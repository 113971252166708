@charset "UTF-8";

// ROBOTO
@import url(https://fonts.googleapis.com/css?family=Heebo:400,500,700);

// GLYPHS
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/glyphicons-halflings-regular.woff') format('woff'),
  url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'),
  url('../fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}

// PRIMARY
@import "components/base";

// PRIMARY - ADDONS
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";

// SECONDARY
@import "components/layout";
@import "components/module";
@import "components/theme";


// HELPERS

/* -- SHORTHAND NOTATIONS
* background: #000 url(images/bg.gif) no-repeat top right;
* font: italic bold .8em/1.2 Arial, sans-serif;
* border: 1px solid #000;
* margin: 10px 5px 10px 5px;
*/

/* -- DECLARATION ORDER
* Positioning, Z-index |||| Box-Model, Floats, Size |||| Typography, Color, Alignment |||| Visual, Border |||| Misc
*/

/* -- THIRD PARTY LINKS FOR HTML
ASCII Codes: http://htmlarrows.com/ |||| http://entity-lookup.leftlogic.com/
HTML Elements: https://html.spec.whatwg.org/multipage/indices.html#elements-3/
HTML Element Attributes: https://html.spec.whatwg.org/multipage/indices.html#attributes-3/
HTML Guide: http://htmlreference.io/
Pug Guide: https://pugjs.org/api/getting-started.html/
Outliner: https://gsnedders.html5.org/outliner/
Semantic Guide: http://codeguide.co/#html
Table Generator: http://www.tablesgenerator.com/
*/

/* -- THIRD PARTY LINKS FOR CSS
Click Chart: http://css3clickchart.com/
CSS Arrows: http://www.cssarrowplease.com/
CSS Guide: http://cssreference.io/
CSS Linear Gradient Generator: http://www.colorzilla.com/gradient-editor/
CSS Matic: http://www.cssmatic.com/
CSS Tricks: https://css-tricks.com/
CSS Values: http://cssvalues.com/
SCSS Guide: http://thesassway.com/
*/
