// BASE.scss
// MEDIA QUERIES DECLARATION
// FONT SIZE MIXIN DECLARATION
// VARIABLES DECLARATION
// SELECTION COLOR DECLARATION
// HYPERLINK MIXIN DECLARATION
// BOOTSTRAP FIXES
// HELPERS

@use "sass:selector";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

// FONT SIZE MIXIN DECLARATION
//noinspection CssOverwrittenProperties
@mixin font-size($sizeValue: 16) {
  font-size: ($sizeValue) + px;
  //font-size: ($sizeValue / 16) + rem;
}

// VARIABLES DECLARATION
// FONT WEIGHTS
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// GRAYSCALES
$gray-base: #000 !default;
$gray-darkest: #0d0d0d;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-medium: #777;
$gray-light: #aaa;
$gray-lighter: #ccc;
$gray-lightest: lighten($gray-base, 93.5%); // #eee

// STANDARD COLORS
$black: $gray-base;
$white: #fff;
$green: #00a651;
$orange: #ff995c;
$blue: #4b8fdd;
$skyblue: #5bc0de;
$lightblue: #e4eefa;
$pale-blue: #d9f2f7;
$red: #cc0033;
$lightgrey: #e8e8e8;
$purple: #a659a6;
$translucent-blue: transparentize($blue, 0.75);
$translucent-red: transparentize($red, 0.75);
$translucent-orange: transparentize($orange, 0.75);

// THEME COLORS.
// Please declare color names with postfix -color. See more - http://thesassway.com/beginner/variable-naming
$primary-color: $gray-dark;
$secondary-color: #c4ebf2;
$secondary-color-darker: #99cccc;

// MORE USEFUL NUMBERS
$line-height: 1.42857;

// TAB STUFF
$shadow-color: #999;
$shadow-width: 3px;
$active-tab-opacity: 0.5;
$passive-tab-opacity: 0.15;

// HYPERLINK MIXIN DECLARATION
@mixin linx($link, $visit, $hover, $active) {
  a {
    color: $link;
    transition: all .6s;
    &:visited {
      color: $visit;
    }
    &:hover {
      color: $hover;
    }
    &:active {
      color: $active;
    }
  }
}


// BOOTSTRAP OVERRIDES

$theme-colors: (
        "primary": $blue,
        "danger": $red
);

$brand-primary: $primary-color;
$brand-secondary: $secondary-color;
$brand-info: $skyblue;
$brand-danger: $red;

$body-bg: #f0fafc;

$yiq-text-dark: $body-color;
$yiq-text-light: rgba(255, 255, 255, 0.87);

$card-spacer-x: 15px;
$card-spacer-y: 10px;
$card-margin-after: 30px;

$h4-font-size: 18px;

$font-family-sans-serif: "Heebo", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
$icon-font-path: 'if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/")';

$link-color: $gray-dark;
$link-hover-color: $gray-darkest;

$breadcrumb-divider-color: $gray-lighter;

$navbar-height: 50px;
$banner-height: 99px;

$navbar-default-color: $primary-color;
$navbar-default-bg: $secondary-color;
$navbar-default-border: transparent;

$navbar-default-link-color: $primary-color;
$navbar-default-link-hover-color: $white;
$navbar-default-link-hover-bg: $primary-color;
$navbar-default-link-active-color: $white;
$navbar-default-link-active-bg: $primary-color;
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

$navbar-light-brand-hover-color: transparent;
$navbar-toggler-hover-color: $gray-lightest;

$nav-tabs-border-width: 0;

$modal-content-border-radius: 6px;
$modal-header-padding-x: 15px;
$modal-header-padding-y: 15px;

$btn-font-size: 12px;
$btn-font-size-lg: 12px;
$btn-padding-x: 15px;
$btn-padding-y: 10px;
$btn-line-height: 1.45;

$input-placeholder-color: $gray-light;

$tooltip-font-size: 11px;
$tooltip-font-weight: 500;

$table-cell-padding: 8px;
$table-sorter-padding: 22px;

// MORE USEFUL MIXINS AND FUNCTIONS

@mixin box-shadow($color, $size:8px) {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 $size $color !important;
}

@mixin is-or-contained-by($selector) {
  @at-root #{selector.unify($selector, &)}, #{selector.nest($selector, &)} {
    @content;
  }
}
@mixin is-or-contains($selector) {
  @at-root #{selector.unify(&, $selector)}, #{selector.nest(&, $selector)} {
    @content;
  }
}

@mixin ellipsis-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;

  // need to add a little extra spacing to keep italics from getting cut off
  padding-right: 2px;
}