@charset "UTF-8";
.Table {
  width: 100%;
}

.evenRow {
  background-color: white;
}

.oddRow {
  background-color: #e8e8e8;
}

.highlight:not(.removed_row) {
  background-color: #d9f2f7;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.ReactVirtualized__Grid {
  outline: 0;
  font-family: Heebo, sans-serif;
  font-size: 14px;
}
.ReactVirtualized__Grid a {
  text-decoration: underline;
  outline: none;
}
.ReactVirtualized__Grid a:hover {
  font-weight: 700;
}

.noScrollBar .ReactVirtualized__Grid {
  -ms-overflow-style: none;
}
.noScrollBar .ReactVirtualized__Grid::-webkit-scrollbar {
  display: none;
}

.react-draggable {
  position: absolute;
  right: -4px;
  top: -1px;
  bottom: 0;
  z-index: 100;
  padding: 8px 5px;
  cursor: col-resize;
}
.grid-cell-header:hover .react-draggable::after {
  content: "";
  display: block;
  height: 100%;
  border-right: 2px solid #e8e8e8;
}

.column-list-spacer {
  flex-shrink: 0;
  width: 1px;
  height: 100px;
  margin: 0;
  padding: 0.5px;
  border: 15px solid transparent;
  background-color: #ccc;
  background-clip: padding-box;
}

.grid-wrapper {
  flex: 1 1 auto;
  width: 100%;
}

.grid-cell {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  min-width: 0;
  margin: 0;
  padding: 0 4px;
}
.grid-cell.first {
  padding-left: 14px;
}
.grid-cell .hoverlay-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
  padding-right: 2px;
}

.grid-cell-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
  padding-right: 2px;
  flex-grow: 1;
  position: relative;
}
.grid-cell-inner > .hoverlay-wrapper:only-child:not(.cell-inner-ellipsis) {
  display: block;
}
.grid-cell-inner .fa-pencil {
  color: #4b8fdd;
}
.grid-cell-inner a:not([href]) {
  text-decoration: underline;
}
.grid-cell-inner a:not([href]):hover {
  text-decoration: underline;
}

.cell-inner-ellipsis {
  display: flex;
  align-items: center;
  width: 100%;
}
.cell-inner-ellipsis > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
  padding-right: 2px;
}

.grid-cell-header {
  position: relative;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 8px 22px 8px 4px;
  outline: 0;
  text-transform: none;
}
.grid-cell-header.first {
  margin-left: 0;
  padding-left: 14px;
}
.grid-cell-header.sort, .grid-cell-header.sort_asc, .grid-cell-header.sort_desc {
  cursor: pointer;
}
.grid-cell-header.sort:after, .grid-cell-header.sort_asc:after, .grid-cell-header.sort_desc:after {
  position: absolute;
  bottom: 15px;
  right: 8px;
  display: block;
  font-family: "FontAwesome";
  opacity: 0.5;
}
.grid-cell-header.sort:after {
  content: "" !important;
  font-size: 0.8em;
  padding-top: 0.12em;
  opacity: 0.2;
}
.grid-cell-header.sort_asc:after {
  content: "" !important;
  font-size: 1em;
  opacity: 0.5;
}
.grid-cell-header.sort_desc:after {
  content: "" !important;
  font-size: 1em;
  opacity: 0.5;
}

.grid-cell-header-spacer {
  width: 10px;
  margin: 0 !important;
}

.grid-header {
  -ms-overflow-style: none;
  scrollbar-width: none;
  will-change: auto !important;
  border-top: 1px solid #ccc;
  text-transform: none;
  border-bottom: 2px solid #ddd;
  background-color: white;
}
.grid-header::-webkit-scrollbar {
  display: none;
}
.grid-header > .ReactVirtualized__Grid__innerScrollContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 700;
}

.grid-body {
  will-change: auto !important;
}

.grid-frozen {
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-right: 1px solid #ccc;
}
.grid-frozen::-webkit-scrollbar {
  display: none;
}
.grid-frozen .last:not(.grid-cell-header):not(.column-right):not(.column-center) {
  padding-right: 10px;
}

.tiered-table .indent-cell, .tiered-table .arrow-cell {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.tiered-table .indent-cell a, .tiered-table .arrow-cell a {
  color: inherit;
}
.tiered-table .branch {
  cursor: pointer;
}
.tiered-table .branch .indent-cell:not(:empty):after, .tiered-table .branch .arrow-cell:not(:empty):after {
  content: "";
  flex-shrink: 0;
  width: 0;
  height: 0;
  margin-left: 10px;
  transform: rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.tiered-table .branch.expanded .indent-cell:after, .tiered-table .branch.expanded .arrow-cell:after {
  transform: rotate(0);
}
.tiered-table .row-depth-0 .indent-cell {
  padding-left: 0em;
}
.tiered-table .row-depth-0 .hide-over-depth-0 {
  visibility: hidden;
}
.tiered-table .row-depth-0 .hide-over-depth--1 {
  visibility: hidden;
}
.tiered-table .row-depth-1 .indent-cell {
  padding-left: 1em;
}
.tiered-table .row-depth-1 .hide-over-depth-0 {
  visibility: hidden;
}
.tiered-table .row-depth-2 .indent-cell {
  padding-left: 2em;
}
.tiered-table .row-depth-2 .hide-over-depth-0 {
  visibility: hidden;
}
.tiered-table .row-depth-2 .hide-over-depth-1 {
  visibility: hidden;
}
.tiered-table .row-depth-3 .indent-cell {
  padding-left: 3em;
}
.tiered-table .row-depth-3 .hide-over-depth-0 {
  visibility: hidden;
}
.tiered-table .row-depth-3 .hide-over-depth-1 {
  visibility: hidden;
}
.tiered-table .row-depth-3 .hide-over-depth-2 {
  visibility: hidden;
}
.tiered-table .row-depth-0, .tiered-table .row-depth-1 {
  font-weight: bold;
}
.tiered-table .row-level-0.evenRow, .tiered-table .row-level-0.evenRow:hover {
  background-color: #fff;
}
.tiered-table .row-level-0.oddRow, .tiered-table .row-level-0.oddRow:hover {
  background-color: #e8e8e8;
}
.tiered-table .row-level-1 {
  background-color: #d5e2f1;
}
.tiered-table .row-level-1.branch.highlight {
  background-color: #9fc6f4;
}
.tiered-table .row-level-1.evenRow {
  background-color: #c6d7ec;
}
.tiered-table .row-level-1.evenRow.branch.highlight {
  background-color: #8cbbf2;
}
.tiered-table .row-level-2 {
  background-color: #9fbcdf;
}
.tiered-table .row-level-2.branch.highlight {
  background-color: #5e9fed;
}
.tiered-table .row-level-2.evenRow {
  background-color: #90b2da;
}
.tiered-table .row-level-2.evenRow.branch.highlight {
  background-color: #4c94eb;
}
.tiered-table .row-level-3 {
  color: #fff;
  background-color: #6694cc;
}
.tiered-table .row-level-3.branch.highlight {
  background-color: #1a76e6;
}
.tiered-table .row-level-3.evenRow {
  background-color: #578ac7;
}
.tiered-table .row-level-3.evenRow.branch.highlight {
  background-color: #176dd3;
}
.tiered-table .row-level-3 .indent-cell:after, .tiered-table .row-level-3 .arrow-cell:after {
  border-top: 7px solid rgba(255, 255, 255, 0.8);
}
.tiered-table .row-level-4 {
  color: #fff;
  background-color: #336199;
}
.tiered-table .row-level-4.branch.highlight {
  background-color: #0f478a;
}
.tiered-table .row-level-4.evenRow {
  background-color: #2e588a;
}
.tiered-table .row-level-4.evenRow.branch.highlight {
  background-color: #0d3d77;
}
.tiered-table .row-level-4 .indent-cell:after, .tiered-table .row-level-4 .arrow-cell:after {
  border-top: 7px solid white;
}
.tiered-table .row-depth-0 {
  background-color: #777 !important;
  color: #fff !important;
}
.tiered-table .row-category-blue {
  background-color: #b3cae6 !important;
}
.tiered-table .row-category-blue.branch.highlight {
  background-color: #75aef0 !important;
}
.tiered-table .row-category-orange {
  background-color: #f0c3a8 !important;
}
.tiered-table .row-category-orange.branch.highlight {
  background-color: #ff9f66 !important;
}
.tiered-table .row-category-green {
  background-color: #a6d9bf !important;
}
.tiered-table .row-category-green.branch.highlight {
  background-color: #67e4a4 !important;
}
.tiered-table .row-category-red {
  background-color: #e6b3bf !important;
}
.tiered-table .row-category-red.branch.highlight {
  background-color: #f07594 !important;
}
.tiered-table .row-category-purple {
  background-color: #e0b8e0 !important;
}
.tiered-table .row-category-purple.branch.highlight {
  background-color: #e87de8 !important;
}

.column-right {
  text-align: right;
  justify-content: right;
  padding-right: 22px;
}
.column-right > .grid-cell-inner {
  display: flex;
  text-align: right;
  justify-content: right;
}

.column-center {
  text-align: center;
  justify-content: center;
  padding-right: 22px;
}
.column-center > .grid-cell-inner {
  display: flex;
  text-align: center;
  justify-content: center;
}

.no-sort {
  padding-right: 4px;
}

