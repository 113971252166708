// LAYOUT.scss
// Declare responsive grid structure here

@import "base";

// HTML, BODY
html {
  font-size: 14px;
  height: 100%;
}

// BODY
body {
  min-height: 100%;
  height: auto !important; // normally it's set to 100% by something I can't change
  font-family: "Heebo", sans-serif;
  line-height: normal;
  color: $primary-color;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// RESPONSIVE MEDIA
img,
embed,
object,
video {
  max-width: 100%;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
}

// SELECTION COLOR DECLARATION - https://css-tricks.com/almanac/selectors/s/selection/
*::-moz-selection,
*::selection {
  color: $white;
  background-color: $black;
}

// BOOTSTRAP FIXES
/*
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * See the Getting Started docs for more information:
 * http://getbootstrap.com/getting-started/#support-ie10-width
*/
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

// MORE NORMALIZATION

button {
  border: none;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
}

h5, .h5 {
  font-size: 1rem;
}
