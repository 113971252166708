// THEME.scss

// 1.0 General
// 2.0 Navigation Bar
// 3.0 System Alerts
// 4.0 Main Page Banner
// 5.0 Page Specific Styles
// 5.1 Login Page
// 5.2 Dashboard Page
// 5.3 Patient List Page
// 5.4 POC Page
// 5.5 Performance Report
// 5.6 TCM Tracker Page
// 5.7 Campaign Page
// 5.8 Admin Page
// 5.9 Site Settings Page
// 5.10 Inactive Patients Modal
// 5.11 Manage Tags Modal
// 5.12 Add Patient Modal
// 5.13 Alerts Page
// 5.14 AWV Worklist
// 5.15 Patient Audits
// 6.0 Batch Print
// 7.0 Server Status
// 8.0 Report Library
// X Footer

// 1.0 General
@use 'sass:color';
@import 'base';

// 2.0 Navigation Bar
// moved to _module.scss... may move back later

// 3.0 System Alerts
.alert, .notification {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;

  --text-color: $white;

  &.alert-danger {
    color: white;
    background-color: $brand-danger;
  }
  .fa.indicator-info {
    display: inline-block;
    color: var(--text-color);
  }
  .fa.alert-close {
    color: var(--text-color);
  }
  .fa:hover {
    opacity: 0.7;
  }
}

.header-alert {
  position: fixed;
  z-index: 1000;
  top: 50px;
  left: 0;
  width: 100%;

  ~ #main-banner {
    margin-top: 52px;
  }
}

// notifications
// XXX these should probably go in _module.css though
.notification {
  height: auto !important;
  // you'd think we could set this style in the react-notification-system default styles in structure/constants.js, but for some reason the library doesn't allow that specific style to be overwritten. Go figure.
}

.notification-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px;
  font-weight: bold;
}

.notification-light {
  --text-color: #{$primary-color};
  color: var(--text-color);
}

.notification-info {
  text-align: center;

  .notification-dismiss {
    // XXX this seems unnecessary. Just specify the notification as { dismissible: "click" }
    display: none;
  }
}

.notification-measure-error {
  a {
    display: block;
    margin-top: 1em;
  }
  strong {
    display: block;
  }
}

// 4.0 Main Page Banner
#main-banner {
  width: 100%;
  height: $banner-height;
  background: url(../images/main_banner.jpg) no-repeat top left $secondary-color;
}

// 5.0 Page Specific Styles
.main-content {
  display: flex;
  flex-flow: column nowrap;
  max-width: 2048px;
  flex-grow: 1;

  .breadcrumb {
    margin-bottom: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    background-color: transparent;

    a:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }
  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.643em;
    font-weight: bold;

    > span {
      font-weight: normal;
      display: inline-block;
      margin-left: 5px;
      color: $blue;
    }
  }
}

.changelog {
  margin: auto;
  width: 100%;
  max-width: 700px;
}

// 5.1 Login Page
.login {
  display: flex;
  flex-flow: column;
  margin-top: -50px; // for the 50px padding on #root
  padding-top: 50px;
  height: calc(100% + 50px);
  min-height: 100vh;
  background: url("../images/login_bg.jpg") no-repeat top center #c6ecf5;
  background-size: cover;

  .card {
    width: 33%;
    min-width: 350px;
    margin: 0 auto 100px;
    padding: 20px;

    @include media-breakpoint-down(md) {
      width: 50%;
      padding: 15px;
    }
  }

  #site-footer {
    margin-top: auto;
  }

  .input-group {
    position: relative;
    width: 100%;
    margin-top: 30px;

    input {
      box-shadow: none !important;
      font-size: 1em;
      display: block;
      width: 100%;
      padding: 10px 10px 10px 5px;
      border: none;
      border-bottom: 1px solid #333;

      &:focus {
        outline: none;

        ~ label {
          font-size: 14px;
          top: -15px;
        }
        ~ .bar {
          &:before,
          &:after {
            width: 50%;
          }
        }
      }

      &:valid {
        ~ label {
          font-size: 14px;
          top: -15px;
        }
      }
    }

    label {
      font-size: 1em;
      font-weight: bold;
      position: absolute;
      top: 10px;
      left: 5px;
      transition: 0.2s ease all;
      pointer-events: none;
      color: $blue;
      letter-spacing: 1px;
    }

    .bar {
      position: relative;
      display: block;
      width: 100%;

      &:before,
      &:after {
        position: absolute;
        bottom: 0.5px;
        width: 0;
        height: 2px;
        content: "";
        transition: 0.2s ease all;
        background: $blue;
      }
      &:before {
        left: 50%;
      }
      &:after {
        right: 50%;
      }
    }
  }
  .has-error .input-group {
    input {
      border-color: $red;

      &:focus ~ .bar {
        &:before,
        &:after {
          background: $red;
        }
      }
      ~ label {
        color: $red;
      }
    }
  }

  .checkbox-inline {
    label {
      font-weight: normal;
    }
  }

  a {
    text-decoration: underline;
  }

  .center-block {
    display: block;
    margin: auto;
    text-align: center;

    .btn {
      margin: 15px 0;
      padding: 10px 60px;
    }
  }

  .logos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 152px;
    width: 300px;
    gap: $card-spacer-x + 20px;
    margin-bottom: $card-spacer-x;

    img {
      max-height: 152px;
      max-width: 100%;
    }
  }

  .logo-hf {
    width: 100%;
  }

  .loader {
    min-height: 0;
  }
}

// 5.2 Dashboard Page

.col-directory, .dashboard-tabs {
  max-height: calc(100vh - #{$navbar-height + $banner-height + 35px * 3} - 30px - 1.8rem - 1.5rem - 22px); // just enough for the Transitions of Care header and a couple rows to peek out
  min-height: 400px;
}

.col-directory {
  display: flex;
  flex-flow: column nowrap !important;

  .tab-content {
    flex: 0 1 auto;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    width: 100%;
  }
}

.directory {
  .card-header {
    padding-bottom: 0;
  }

  .card-body {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    margin: 0 -15px;
    padding-bottom: 0;
    padding-top: 10px;
  }

  .physician-list {
    margin-bottom: 0;
    padding: 0;
    overflow: auto;

    > li {
      list-style: none;
      padding: 15px;

      .clinic-name {
        font-size: 1em;
        font-weight: bold;
        display: block;
        margin-top: 0;
        margin-bottom: 5px;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: $gray-medium;
        }
      }

      .clinic-physician {
        font-size: 0.875em;
        display: block;
      }

      a.clinic-physician {
        cursor: pointer;
        text-decoration: underline;
      }

      .patient-count {
        display: inline-block;
        float: right;
      }
    }
  }
}

.directory-filters {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 10px -10px 0 0;

  > * {
    flex: 1 0 200px;
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .input-group-btn  > .btn {
    height: 36px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }
  .input-group-focus {
    .form-control {
      border-right: 0;
    }
  }
}


#physician-list .dataTables_wrapper {
  > .row > .col-sm-6 {
    display: none;
  }
  table thead {
    display: none !important;
  }
  .dataTables_filter {
    display: none;
    label {
      margin: 0 !important;
    }
  }
}

$footer-height: 12px * 1.5 * 4 + 20px + $card-margin-after + 6px; // not sure where the 6px comes from though

.dashboard-group {
  min-height: calc(100vh - #{$navbar-height + $banner-height + $footer-height + $card-margin-after} - 1.8rem);
  > * {
    margin-bottom: $card-margin-after;
  }
}


.dashboard-tabs {
  .qsr-dashboard {
    .ler-empty {
      padding: 0 15px 70px;
    }
  }
}

.cost-dashboard .tiered-table .row-depth-0 {
  background-color: $gray-medium;
  color: $white;

  &.branch:hover {
    background-color: $gray;
  }
}

.cost-dashboard-filters {
  .period {
    display: flex;
    align-items: baseline;

    .button-group {
      flex-grow: 1;
      margin: -3px 0 -3px 10px;
    }
  }
}

.dashboard-measure-name {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;

  .guide-link-measure {
    flex: 1 0 12px;
    margin-right: 5px;
  }
}

.outreach_tab {
  .card-header {
    border-bottom: 1px solid $card-border-color;
  }
}

.category-tree {
  .category-select {
    /*column-count: 2;
    > * {
      break-inside: avoid;
    }*/
    // Ideally we'd just have a column layout, but that comes with a lot of issues. For now, let's use a flex/grid approach.

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    > * {
      flex-basis: calc(50% - 8px / 2);
      // bottom margin is 8px. TODO put in a variable.
    }

    @supports (display:grid) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;

      > .selected {
          grid-row-end: span 2;
      }
    }
  }
  .subcategory-select {
    margin-top: 10px;
  }
  .form-group {
    margin-bottom: 0;
  }

  .category-tree-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5em;

    label {
      margin: 0;
    }
  }
  .check-all {
    padding: 1px 10px;
  }

  &.category-tree-none_checked {
    .rct-icon-uncheck {
      &:before {
        content: "\f046";
        opacity: 0.5;
      }
    }
  }
}

.toc-list {
  .filter-status {
    width: 100%;
    margin-top: 8px;
  }
  .toc-timestamp {
    margin-left: 5px;
    margin-right: auto;
    font-size: 12px;
  }
  .grid-wrapper {
    max-height: 500px;
}
  &.toc-small .grid-wrapper {
    max-height: 300px;
  }
}

.ler-error, .ler-empty, .spinner-loading {
  .toc-list & {
    height: 500px;
  }
  .toc-small & {
    height: 300px;
  }
}


.toc-filters {
  .divider.inner {
    margin: 15px 15px 25px;
  }
  .hidden-lg {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.at_risk {
  text-align: center;
}

.data_coverage {
  .card-header, .form-group {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    margin-bottom: 0;
  }

  .card-header {
    align-items: center; // otherwise the Settings button looks a little off

    .card-title {
      display: block;
      margin-right: auto;
    }

    > .fa {
      margin-right: 0;
    }
  }

  .form-group {
    align-items: baseline;

    .select {
       min-width: 200px;
    }
  }
}

.risk-dashboard .bar-indicator {
  .current_rate {
    background-color: $orange;
    &.success {
      background-color: $green;
    }
  }
}


// 5.3 Patient List Page

.patient-list-chart {
  height: auto !important;

  button {
    padding: 0;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  .card-title {
    line-height: 1.4;
  }

  .toggle-switch {
    height: auto;
  }

  .hidden {
    display: none;
  }

  &.cost-spending-chart .card-header {
    border-bottom: none;
  }

  .pie-chart caption{
    display: none;
  }
}

.patient-list-chart-expander {
  display: flex;
  text-align: left;
}

.patient-list {
  overflow: hidden;
  max-height: calc(100vh - #{$navbar-height + $banner-height} - 2.25rem - 1.643em - 50px); // just enough to fill the page after the header

  .dataTables_wrapper {
    > .row > .col-sm-6 {
      display: none;
    }
    .dataTables_filter {
      display: none;
      label {
        margin: 0 !important;
      }
    }
    .dataTables_info {
      display: none;
    }
  }
  .filters {
    #payer-select,
    #product-select {
      overflow-y: auto;
      height: 335px;
      @include media-breakpoint-down(md) {
        height: 200px;
      }
    }
    #measures-list {
      overflow-y: auto;
      height: 568px;
      @include media-breakpoint-down(md) {
        height: 646px;
      }
    }
  }
  .lname {
    &:after {
      content: ", ";
    }
  }
}

.patient-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .filter-status {
    padding-top: 1px;
    // this padding helps with keeping the flex alignment look right

    @include media-breakpoint-down(md) {
      order: 10;
      flex-basis: 100%;
      margin-top: 6px;
    }
  }

  .filter-button {
    margin-left: -9px;
    margin-right: -7px;
  }

  .clear-filters {
    margin: 0 20px;
    transition: margin 0s;

    @include media-breakpoint-down(md) {
      margin-left: auto;
    }

    &:hover {
      background-color: darken($blue, 10);
      color: white;
    }
  }

  .clickable-icon {
    margin-right: 0 !important;
  }

  .campaign-controls {
    margin-right: auto;
  }
}

.patient-list-page-header {
  align-items: flex-end;
  margin-bottom: 10px;

  h1 {
    margin: 20px 0 0 0;
  }

  .patient-list-pivot {
    vertical-align: text-bottom;
    height: auto;
    margin-left: 10px;
    padding: 2px 0;
  }

  .guide-link {
    padding: 5px 10px;
    font-size: 16px;
  }

  .population-check {
    display: inline-block;
    margin-left: 20px;
    margin-right: auto;
    margin-bottom: -1px;
    font-size: 16px;
    cursor: pointer;
  }
}

.camapign-page-header {
  align-items: flex-end;
  margin-bottom: 10px;
  gap: 10px;

  h1 {
    margin: 20px auto 0 0;
  }
}

.pp_chart-pivot-label {
  display: block;
  margin: 2px 10px 0 0;
  font-size: 0.875em;
  font-weight: normal;
}

.pl-icon {
  line-height: 16px;

  .fa {
    vertical-align: middle;
    font-size: 1.333em;
    line-height: 16px;
  }
  img {
    vertical-align: middle;
    height: 16px;
    border-radius: 3px; // to match up with the visual style of the IP and ED badges
    margin-bottom: 1px; // helps with alignment
  }
}

.button-group.field-cost_span {
  height: 36px;
  margin: 0;

  button:disabled {
    pointer-events: none; // or else hovering won't trigger OverlayTrigger, since that doesn't normally work on disabled elements
  }

  :first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  :last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

.field-include_unserviced label {
  padding: 3.5px 0; // make it as tall as one of the <hr>s, including margins
}

.cmi-popover {
  width: 600px;
}

// 5.35 Patient List Filter Form and POC form

form.filters, .poc_measures {
  .form-group .form-control {
    &:hover {
      border-color: hsl(0,0%,70%);
    }
    &:focus {
      border-color: $blue;
    }
  }
}

.filter-scope-explainer {
  p {
    margin-bottom: 0.5rem;
  }
}

.filter-panel {
  .form-group {
    min-width: 120px;
  }
}

.filter-fuzzy_search {
  display: flex;
  flex-flow: row wrap;

  input {
    flex: 0 1 100%;
  }
  .filter-fuzzy_search-label {
    order: -1;
    margin-right: 5px;
  }
  .checkbox-field {
    min-width: min-content;
    margin-bottom: 0;
  }
}

.contract_numbers-tooltip .tooltip-inner {
  max-width: 400px;
  word-break: break-word;
}

// 5.36 Care Management page

.care_management-header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin: 20px 0 10px;

  h1 {
    margin: 0 20px 0 0;
  }

  .page-header-group {
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .button-group {
    margin: 0;
  }

  .pull-right {
    margin-left: auto;
  }
}

// 5.4 POC Page

.poc-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: $navbar-height - 1px;
  z-index: 1015;
  gap: 5px 30px;
  margin: 0 0-$card-spacer-x $card-spacer-x - $card-spacer-y;
  padding: 0 $card-spacer-x $card-spacer-y;
  background-color: $body-bg;
}

.poc-header-shadow-box {
  $height: $card-spacer-y;

  display: block;
  position: sticky;
  top: 0; // updated by js
  z-index: 1010;
  height: $height;
  margin: -$height * 3 0-$card-spacer-x $height * 2; // keep this hidden behind .poc-header while we're still in flow
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.23);

}

.poc-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  align-self: baseline;

  h1 {
    margin-bottom: 0;
  }
}

.poc-buttons {
  margin-top: 5px;

  > * {
    margin: 0;
    + * {
      margin-left: 10px;
    }
  }
}

.poc-header-info {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  align-self: baseline;
  gap: 20px;
  margin: 0 auto 0 0;

  > div {
    display: flex;
    flex-flow: row;
    gap: 5px;
  }

  dd {
    margin: 0;
  }
}

.poc_measures {
  .card-body {
    overflow-x: auto;
  }
  .widget {
    border: 0;
    border-top: 1px solid #ddd;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }

  .checkbox label, label.checkbox {
    position: relative;
    font-weight: bold;
    color: $gray-light;
  }

  input[type=checkbox] {
    margin-right: 6px;
    position: relative;
    top: 1px; // this looks a little nicer
  }

  .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
  }

  .disabled {
    input,
    .Select-clear-zone,
    .input-group-text {
      cursor: default;
      background-color: $input-disabled-bg;
      border-color: $input-border-color;
    }
  }
  .select__control--is-disabled {
    cursor: default;
    background-color: $input-disabled-bg;
    border-color: $input-border-color;
  }
}

.incentivized-tooltip {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;

    &.bulleted {
      list-style-type: disc;
      padding: 0 1.4rem;
    }
  }

  h5 {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
  }

  .tooltip-inner {
    padding: 0;

    > div {
      padding: 5px 10px;

      + div {
        border-top: 1px solid $gray-medium;
      }
    }
  }

  .compliance-warning {
    color: $orange;
    text-align: center;
  }
}

.poc-row {
  .col-poc-info {
    min-height: 320px; /// XXX arbitrary
    @include media-breakpoint-down(lg) {
      margin-bottom: $card-margin-after;
    }
  }

  > * {
    max-height: 600px; // XXX also arbitrary
  }

  .tab-pane > .card {
    &:has(.card-body > .loader) {
      height: 100%;
    }
  }

  .loader {
    min-height: 300px;
  }
}

.poc-comments {
  form {
    .form-group:last-child {
      margin-bottom: 0;
    }
  }
  .card-body {
    padding-top: 0;
  }

  .row {
    row-gap: 15px;
  }

  #commentInput {
    resize: vertical;
    height: auto;
    min-height: 5*20px + 12px;
    padding: 6px 12px;
  }
}

.poc-comments-list {
  overflow: auto;
  max-height: 357px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.poc-comment {
  position: relative;
  display: block;
  padding: 10px 15px;

  &.deleted {
    color: $gray-light;
    text-decoration: line-through;
  }

  &:first-child {
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
   }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:nth-child(odd) {
    background-color: $lightgrey;
  }

  p {
    margin: 0;
  }

  .date-posted {
    font-weight: bold;
  }

  .btn-blank {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.patient-info {
  @include media-breakpoint-up(xl) {
  margin-bottom: 0;
  }

  .card-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0;

    > .fa {
      cursor: pointer;
      margin-right: auto;
    }
  }
  .card-title {
    padding: 10px 7px 9px 15px;
    line-height: 1.125em;
  }

  .dataTables_wrapper {
    overflow: auto;

    > .row > .col-sm-6 {
      display: none;
    }
    table {
      thead {
        display: none !important;
      }
      tbody {
        td {
          padding: 5px 15px;
        }
        tr > td:first-child {
          box-sizing: border-box;
          flex: 0 0 125px;
          font-weight: bold;
        }
      }
    }
    .dataTables_filter {
      display: none;
      label {
        margin: 0 !important;
      }
    }
  }
}

.patient-info-coverage {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.deactivation-notice {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 10px 15px;
  background-color: $red;
  color: white;
  font-weight: bold;
}

.deactivate-patient {
  margin: -10px 15px -10px 0;
}

.reactivate-patient {
  margin: -10px 0;
}

#priority-attention {
  .dataTables_wrapper {
    > .row > .col-sm-6 {
      display: none;
    }
    .dataTables_filter {
      display: none;
      label {
        margin: 0 !important;
      }
    }
  }
}

.risk-cell-risk_type{
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  padding-right: 15px;

  .cell-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .indicator-info {
    flex: 0 0 auto;
  }
}

.poc-comments {
  form {
    .form-group:last-child {
      margin-bottom: 0;
    }
  }
  .card-body {
    padding-top: 0;

    > div {
      max-height: 100%;
    }
  }
  #commentInput {
    resize: vertical;
    height: auto;
    min-height: 5*20px + 12px;
    padding: 6px 12px;
  }
  #posted-comments {
    overflow: auto;
    max-height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: block;
      padding: 10px 15px;

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &:nth-child(odd) {
        background-color: $lightgrey;
      }
      .date-posted {
        font-weight: bold;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}


// XXX this feels like it should go in module maybe? Seems too generic to just go here
// XXX or alternatively wrap this in an additional selector
.card-group {
  flex-flow: column nowrap;

  .card {
    margin-bottom: -1px;

    &:first-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    & + .card {
      margin-top: 0;
      border-radius: 0;
    }

    .card-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .card-title {
      display: block;
      width: 100%;

      a, button {
        position: relative;
        display: block;
        padding: 0;
        width:100%;
        background-color: transparent;
        color: inherit;
        cursor: pointer;
        font: inherit;
        text-align: left;
        text-decoration: none;

        &:focus {
          outline: none;
        }

        .toggle {
          font: normal normal normal 14px/1 FontAwesome;
          font-family: "FontAwesome";
          font-size: inherit;
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          color: $blue;

          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        &[aria-expanded="true"] .toggle:before {
          content: "\f077";
        }
        &[aria-expanded="false"] .toggle:before {
          content: "\f078";
        }
        mark {
          font-size: 10px;
          font-weight: bold;
          line-height: 22px;
          position: absolute;
          top: -2px;
          width: 22px;
          height: 22px;
          margin-left: 15px;
          padding: 0;
          text-align: center;
          color: #fff;
          -webkit-border-radius: 22px;
          -moz-border-radius: 22px;
          border-radius: 22px;
          background-color: $red;
        }
      }
    }

    .card-body {
      padding: 15px;
      background-color: mix($blue, white, 15%);
      h4 {
        font-size: 1em;
        margin-top: 10px;
        color: $blue;
      }
      hr {
        margin: 5px -15px;
        border: 0;
        border-top: 1px solid mix($blue, white, 50%);
      }
    }
  }
}

#er-ip-admissions {
  .card-body {
    max-height: 350px;
  }

  .dataTables_wrapper {
    > .row > .col-sm-6 {
      display: none;
    }
    .dataTables_filter {
      display: none;
      label {
        margin: 0 !important;
      }
    }
  }
}

.poc-letters {
  .card-header {
    gap: 10px;
  }
  .poc-print {
    margin: -5px 0;
  }
  .poc-letter-settings {
    margin-left: auto;
  }
}

.patient-info-edit {
  background-color: rgb(228, 238, 250);

  .row {
    display: flex;
    flex-flow: row wrap;
  }

  .card {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// layout of measures table cells
.measures.table {
  display: block;

  > * {
    display: block;

    > tr {
      display: flex;

      > th, > td {
        display: block;
        box-sizing: border-box;
      }
    }
  }

  .measures-name {
    flex: 4 0 160px;

    .payer-logos {
      display: inline-block;
    }
  }
  .measures-compliance {
    flex: 1 0 100px;
    text-align: center;
    @include media-breakpoint-down(md){
      flex-basis: 80px;
    }
  }
  .measures-value-last {
    flex: 1 0 130px;
    @include media-breakpoint-down(md){
      flex-basis: 100px;
    }
  }
  .measures-date-last {
    flex: 1 0 110px;
    @include media-breakpoint-down(md){
      flex-basis: 90px;
    }
  }
  .measures-value-new {
    flex: 4 0 140px;

    .indicator-info {
      margin-left: 5px;
    }
  }
  .measures-date-new {
    flex: 1 0 160px;
    text-align: left;
  }
  .measures-history {
    flex: 1 0 65px;
    padding-left: 3px;

    @include media-breakpoint-down(md){
      flex-basis: 55px;
    }

    .icon-button {
      height: 36px;
      margin-right: 2px;
      padding: 7px 5px 5px; // looks slightly misaligned otherwise?
    }
  }

  > thead {
    th {
      border: 0;
      @include media-breakpoint-down(md) {
        padding: 5px;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
  > tbody {
    td {
      border: 0;
      vertical-align: top;

      @media (max-width: map-get($grid-breakpoints, lg)) {
        padding: 5px;
      }

      .form-group {
        margin-bottom: 0;
      }

      &:first-child {
        padding-left: 0;
      }

      .input-seperator {
        text-align: center;
      }

      .fa-line-chart {
        cursor: pointer;
      }

      .form-control-static.disabled {
        color: $gray-light;
      }

      .form-control-static, .checkbox {
        margin: 0;
        padding: 7px 0;
      }
    }
  }

  .fa {
    display: inline-block;
  }

  .measure-text > * {
    margin-right: 5px;
  }
  .guide-link-measure {
    margin-left: 0; // normally it has a left margin, but instead everything in this cell has a right margin
    margin-right: 10px; // put a little more spacing between this and the icons, to match the spacing between the icons
  }

  .payer-logos img {
    width: 20px;
  }

  .delete-measure .fa {
    color: $red;
  }
  .form-control-static.due {
    color: $red;
  }
}

.popup-history {
  &:hover,
  &:focus {
    outline: none;
  }
  .fa {
    color: $blue;
  }
  [disabled] .fa {
    color: $gray-light;
  }
}

.measure-incentivized {
  font-weight: bold;
}

.measure-composite {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  > .submeasure-composite {
    flex: 1 1;

    + .submeasure-composite {
      margin-left: 8px;
    }
  }
}

#conditions {
  overflow: auto;

  > .card + .card {
    margin-top: 0;
  }

  .loader {
    min-height: auto;
  }
}

.care_team {
  overflow: hidden;

  .card-body {
    display: flex;
    flex-flow: column nowrap;
  }
  > .card {
    overflow: hidden;
  }

  .loader {
    min-height: auto;
  }

  .care_manager-button {
    display: inline;
    margin-left: 10px;
  }
}

#care_managers {
  ~ * {
    margin-top: 0;
  }

  .fa {
    color: $blue;
  }
  .care_manager-plural {
    color: #ccc;
  }
  .inactive {
    color: #777;
    font-style: italic;
  }
  .show-history {
    float: right;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #ccc;
  }
  .toggle-switch {
    margin-left: 10px;
    height: auto
  }
  .show-history-label {
    margin: 0;
    cursor: pointer;
  }
}

.care_manager-button {
  cursor: pointer;
}

.add_risk-button {
  margin-left: 10px;
  color: $blue;
  cursor: pointer;
}

.patient-costs {
  flex-flow: row wrap;
  align-items: stretch;
  margin: 0;
  padding: $card-spacer-x 0;
  overflow: hidden;

  > * {
    + * {
      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
      @include media-breakpoint-down(md) {
        margin-top: $card-spacer-x;
      }
    }
    > .card {
      height: 100%;
      margin: 0;
    }
  }

  .card-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .card-body {
    display: flex;
    flex-flow: column nowrap;
  }

  .button-group {
    margin: -3.5px 15px;
    padding: 0;
  }

  .modal-link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.patient-cost-table {
  .card-header > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .card-body {
    overflow-x: hidden;
  }

  .payer-icon {
    margin: 0 0 0 5px;
  }

  .payer-cell {
    @include ellipsis-overflow;
  }
}
.patient-payer-tooltip .payer-icon {
  margin: 0 0 0 5px;
}

.cost-chart {
  margin-right: 15px;
  height: 315px;
}

.patient-cost-chart {
  dl {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-right:-5px;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);

    > div {
      display: flex;
      flex-flow: row nowrap;
      margin: 5px 10px;
    }

    dt {
      white-space: nowrap;
      margin-right: 5px;
    }

    dd {
      margin: 0;
    }

    &.one-row {
      flex-flow: row nowrap;

      > * + * {
        margin-left: 0;
      }
    }
    &.two-row {
      flex-flow: column;

      @include media-breakpoint-down(md) {
        flex-flow: row nowrap;

        > * + * {
          margin-left: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        > * + * {
          margin-top: 0;
        }
      }
    }
  }

  .card-header {
    flex-flow: row nowrap;
    display: flex;
    align-items: baseline;
  }

  .card-title {
    margin-right: 10px;
    line-height: 21px;
  }

  .payer-select {
    flex: 1 1 50%;
    min-width: 200px;
    max-width: 300px;
    margin-right: 10px;
  }
}

.patient-cost-modal {
  .modal-content {
    max-height: calc(100vh - 2 * #{$modal-dialog-margin-y-sm-up});
  }
  .modal-body {
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden auto;
  }

  .modal-footer {
    justify-content: space-between;
  }

  section {
    padding: 1rem 0;

    + section {
      border-top: 1px solid $gray-light;
    }
  }

  h5 {
    font-size: 1.1em;
    text-transform: uppercase;
    color: darken($blue, 30);
  }

  strong {
    color: $red;
  }

  dd, ul {
    margin-bottom: 0;
  }

  .count {
    font-weight: normal;
  }

  .metadata {
    display: flex;
    flex-flow: row wrap;

    > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;

      &:nth-child(2n) {
        text-align: right;
        justify-content: flex-end;
      }
    }
    dd {
      //flex-grow: 1;
      margin-left: 5px;
    }
  }

  .codes {
    $padding: 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-left: -$padding;

    > div {
      flex: 1 1 25%;
      padding-left: $padding;

      + div {
        margin-left: $padding;
        border-left: 1px solid $gray-lighter;
      }

      &.smaller {
        flex-basis: 20%;
      }

      &.inline-tiles dd {
        display: inline-block;
        margin-right: 5px;
      }
    }

    .cost {
      color: darken($red, 10);
    }
  }
}

.code-tile {
  padding: 0 3px;
  border-radius: 4px;
  background-color: lighten($blue, 7);
  color: white;
  font-size: 0.9em;
  font-weight: bold;
}

.code-tile-hoverable {
  background-color: $blue;

  &:hover {
    background-color: darken($blue, 20);
  }
}

.procedure-cost {
  color: $blue;
  font-weight: bold;
}

// Patient Tags tab

.patient-tags {
  .card {
    height: 100%;
    margin-bottom: 0;
  }

  .card-header > button {
    margin: -4px 0 -4px 15px;
  }

  .toggle-switch {
    margin-left: 10px;
    height: auto
  }

  .select__control {
    height: 36px;
    min-height: 36px; // to overwrite the current min-height
  }
}

.patient-tags-modal p {
  margin-bottom: 0;
}

// tag badges

.tag-badges-spacer {
  height: 30px;
  flex: 0 0 30px;
}

.tag-badges {
  padding: 1em 18px;

  h4 {
    display: inline-block;
    font-size: 1em;
    font-weight: bold;
    margin: 0 10px 0 0;
  }
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  .badge {
    margin-right: 5px;
    font-size: 90%;
    font-weight: normal;
  }
  .badge-primary {
    background-color: $blue;
  }
}

.identifiers {
    display: inline;
    margin: 0;
    padding: 0;

  li {
    display: inline;
    list-style-type: none;

    &:not(:last-child):after {
      content: ";";
      margin-right: 5px;
    }
  }

  .identifier-primary {
    font-weight: bold;
  }
}

.identifiers-tooltip {
  li {
    align-items: baseline;
  }

  .identifier-primary {
    font-weight: bold;

    &:before {
      content: "\02B24";
      margin-right: 5px;
    }
  }
}

// 5.4.1 Utilization Details

.utilization-details {
  overflow: hidden;

  .main-content:has(&) {
    max-height: calc(100vh - #{$navbar-height + $banner-height + $card-spacer-x - $card-margin-after});
    min-height: 700px;

    @include media-breakpoint-down(sm) {
      max-height: 800px;
      min-height: auto;
    }
  }

  .utilization-details-filters {
    padding-bottom: 0;
  }
}
.utilization-details-header {
  align-items: flex-end;
  flex-flow: row wrap;
  gap: $card-spacer-y $card-spacer-x;
  margin: 10px 0;

  h1 {
    min-width: auto;
    margin: 10px auto 0 0;
  }
}

.utilization-details-settings {
  box-sizing: content-box;
  padding: 5px;
}



// 5.5 QSR / Performance Report

body.report {
  #generate-report {
    margin-bottom: $card-margin-after;
  }
}

#qsr {
  .dataTables_wrapper {
    > .row > .col-sm-6 {
      display: none;
    }
    .dataTables_filter {
      display: none;
      label {
        margin: 0 !important;
      }
    }
  }
  .filters {
    #payer-select,
    #product-select {
      overflow-y: auto;
      height: 446px;
      @include media-breakpoint-down(md) {
        height: 200px;
      }
    }
    #measures-list {
      overflow-y: auto;
      height: 446px;
      @include media-breakpoint-down(md) {
        height: 670px;
      }
    }
  }
}

.qsr {
  max-height: calc(100vh - #{$navbar-height + $banner-height} - 2.25rem - 1.643em - 50px); // just enough to fill the page after the header

  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: $secondary-color !important;

    .orangeHighlight {
      background: $secondary-color;
    }
  }

  .filters-display {
    margin-right: auto; // XXX MERGED
  }

  .custom_scrollbar {
    position: static;
    bottom: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .card-header {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
  }

  .card-title {
    padding-top: 5px;
  }
  .filter-status {
    flex: 0 1 100%;
    padding-top: 5px;
  }
}

.qsr-header {
  .filter-favorites-dropdown {
    margin-right: auto;
  }
}

.qsr-export {
  display: flex;
  height: 77px;
  text-align: right;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 15px;
  }
}

%chart-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //height: 43px;
  border-bottom: 1px solid #ccc;
}

.qsr-performance-chart.card {
  .card-header {
    @extend %chart-card-header;
    align-items: center;
  }
  .card-title {
    margin-right: 2rem;
  }

  .card-title-prior {
    color: inherit !important;
    white-space: nowrap;

    &.hide-prior {
      color: #ccc !important;
    }
  }

  .toggle-switch {
    height: auto;
    vertical-align: text-top;
  }

  .bb-legend-item[class*=prior] {
    .bb-legend-item-tile {
      stroke-dasharray: 2;
    }
  }

  [class|=bb-tooltip-name--prior] {
    td > span {
      background: repeating-linear-gradient(90deg, transparent, transparent 2px, white 2px, white 4px);
    }
  }

  .bb-line {
    stroke-width: 3px;
  }

  .prior {
    stroke-dasharray: 3;
  }

  .no-circles {
    circle:not(._expanded_) {
      display: none;
    }
  }
}

.qsr-chart {
  margin: 10px 15px 10px 0;
  height: 400px; // will keep growing every time you toggle views otherwise
}

.measure-performance-link {
  @include ellipsis-overflow;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: $blue;
  }
}

.qsr-performance-placeholder {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qsr-measures-tree {
  overflow: auto;
  max-height: 400px;
}

.qsr-supplements {
  gap: $card-margin-after 0;
  margin-bottom: $card-margin-after;

  @include media-breakpoint-down(lg) {
    > :first-child > .card {
      position: relative;
      max-height: 400px;
    }
  }
}

//5.51 Cost Report
.cost-report-table {
  max-height: calc(100vh - #{$navbar-height + $banner-height + $card-spacer-x + $card-margin-after} - 1.8rem - 1.5rem - 0.75rem);
}
.cost-report-table, .cost-utilization {
  .card-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;

    > div {
      display: flex;
      flex-flow: row nowrap;
    }

    .button-group {
      margin-right: 0;
    }
  }
  .card-title {
    padding-right: 15px;
  }
  .filter-status {
    flex: 0 1 100%;
    padding-top: 5px;

    ul {
      margin-left: -5px;
    }
  }
}

.cost-supplements {
  gap: $card-margin-after 0;
  margin-bottom: $card-margin-after;

  @include media-breakpoint-down(md) {
    > :first-child > .card {
      position: relative;
      max-height: 400px;
    }
  }
}

.procedures-table {
  .card-header {
    display: flex;
    align-items: center;
  }
  .search-bar {
    margin: -6px 15px;

    .btn {
      padding: 6px 10px 4px 10px;
    }
  }
}

.cost-spending-chart {
  .card-header {
    @extend %chart-card-header;

    .button-group {
      margin-right: 0;
    }
  }
  .card-title {
    line-height: 1.3;
  }
  .cost-chart {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

//5.55 Utilization Report

.cost-utilization-report {
  display: flex;
  flex-flow: row wrap;
  padding: $card-spacer-x / 2;

  .card {
    max-height: 500px;
    flex: 1 0 500px;
    margin: $card-spacer-x / 2;
  }

  .card-title {
    flex-grow: 1;
  }

  .card-body {
    padding: 0;
  }
}

.cost-utilization-chart {
  .card-body {
    padding-right: $card-spacer-x;
  }
}
.cost-utilization-table {
  .card-body {
    overflow-y: hidden;
  }
}


// 5.6 TCM Tracker Page
.tcm-tracker {
  overflow: hidden;

  .main-content:has(&) {
    max-height: calc(100vh - #{$navbar-height + $banner-height + $card-spacer-x - $card-margin-after});
    min-height: 400px;
  }

  .remove-icon {
    color: $red;
  }
  .dt-center {
    text-align: center;
  }
  .rdtPicker {
    min-width: 250px;
  }
  .tcm-filters {
    width: 100%;
    margin-top: 8px;
  }
}

.column-widget, .column-widget .grid-cell-inner {
  overflow: visible !important;
}

$input-height: 31px;

span.tcm-input-text {
  overflow: clip;
  overflow-clip-margin: content-box;
  text-overflow: ellipsis;
  padding: 4px 0.75rem; // to match the input's spacing
  cursor: text;
}

.tcm-input-text {
  height: $input-height;
  width: calc(100% - 5px);

  &:focus-within {
    border-color: $blue;
    @include box-shadow($blue);
  }

  &.placeholder {
    color: $gray-light;
    user-select: none;
  }
}

.tcm-input-dropdown {
  overflow: visible;
  height: $input-height;
  width: calc(100% - 5px);

  &:focus-within {
    @include box-shadow($blue);
  }

  > div {
    height: $input-height;
    min-height: $input-height;
    border-width: 1px !important; // tries to get thicker on focus otherwise
    box-shadow: none;

    > div {
      height: 100%; // otherwise vertical alignment gets out of whack

      > div {
        // also keeps vertical alignment correct
        margin-top: 1px;
        margin-bottom: 0;
      }
    }
  }

  &:not(.focused) > div > div:first-child > div:first-child {
    // more vertical alignment tricks
    margin-top: 2px;
  }

  div[class$=placeholder] {
    color: $gray-light;
  }

  &.loading > div {
    border-color: $input-border-color;
    background-color: #e9ecef;
  }

  .placeholder:after {
    content: "Select...";
    color: $gray-light;
  }


}

.tcm-dropdown-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: pink;
}

.grid-cell .tcm-input-text-wrapper {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: visible;
}

.text-loading {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 14px;
}


// 5.7 Campaigns
#campaigns {
  .collapse {
    max-height: 230px;
  }
  .loader {
    min-height: 0;
  }
  .remove-icon {
    color: $red;
  }
  .dt-center {
    text-align: center;
  }
}

.toggle-switch input[type="checkbox"]:checked + label {
  background-color: $blue;
}

.pin-icon-org {
  width: 18px;
}
.pin-icon-fixed {
  font-size: 1.125em;
  color: #999;
  padding: 5px 2px 0; // line up the thumbtack icon with the others
}

.pin-icon {
  font-size: 1.125em;
  color: #999;
  cursor: pointer;

  &:hover {
    color: #f7931e;
  }
}

.unpin-icon {
  font-size: 1.125em;
  color: #f7931e;
  cursor: pointer;

  &:hover {
    color: #999;
  }
}

// 5.8 Admin Page

.admin, .admin-modal {
  .ReactTable {
    overflow: hidden;
  }
  .rt-tr-group {
    flex: 0 0 auto;
  }
  .icon-button {
    width: 21px;
  }
}
.admin-edit-practices {
  .search-bar {
    margin-bottom: 15px;
  }

  .ReactTable {
    height: 450px;
  }

  .disabled {
    color: $gray-light;
  }

  .text-danger {
    margin-left: 5px;

    &:not(:empty) { margin-top: 0.6rem; }
    + .text-danger { margin-top: 0; }
  }
}

.admin-set_panel_default {
  margin: 1rem 0 0;
}

.admin-users-table, .admin-practices-table {
  height: 500px;
  margin-bottom: 15px;

  .practice-inactive {
    color: $gray-light;
  }
}

.admin {
  .icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
    padding: 0;
    margin-right: 3px;
    height: inherit;
  }

  .table-description {
    padding: 1rem 0 0;
  }
}

.admin-user-detail {
  flex-direction: row;
  margin: 0 15px 0 0;
  padding: 0 10px 10px;

  .card {
    margin-bottom: 0;
  }

  .rt-tbody {
    min-height: 35px;
  }

  .rt-noData {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translate(0, 0);
    background-color: white;
  }

  > * {
    padding: 0;

    + * .card {
      margin-left: 10px;
    }
  }

  .disabled {
    color: $gray-light;
  }
}

.admin-modal {
  .provider-panels {
    overflow-y: auto;
    max-height: 66vh;

    .loader {
      height: 40vh;
      min-height: 100px;
    }

    .disabled {
      color: $gray-light;
    }
  }
  .fa-minus-circle {
    color: darkgray;
  }

  .nav-tabs > .nav-link.disabled:hover {
    background-color: inherit;
    border-color: inherit;
  }

  .edit-user-tags {
    .tag-search {
      margin: 0 0 10px;
    }
    .ReactTable {
      max-height: 400px;
    }
    .hoverlay-wrapper {
      display: inline-block;
      padding: 4px 10px;
      line-height: 0;
    }
  }

  .text-danger {
    // already set to a red color in bootstrap
    font-weight: bold;
  }
}

.tag-search .btn {
  padding: 6px 10px 4px 10px;
}

.admin-controls {
  display: flex;
  align-items: flex-start;

  > * {
    margin: 10px 0;
    height: 36px;

    + * {
      margin-left: 10px;
    }
  }

  > button {
    white-space: nowrap;
  }

  > .search-bar {
    flex-grow: 1;
  }
}

// a different display scheme that puts the controls all on the same line
/*.admin-controls {
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 0;

  > .form-group {
    flex-grow: 1;
    margin-bottom: 0;
  }

  .btn-lg {
    height: 36px;
  }

  > * + * {
    margin-left: 10px;
  }
}*/

.providers-th {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.providers-th-sm > * {
  font-size: 0.7em;
  white-space: normal;
}

.providers-practice_unit {
  > h4 {
    // we have both margin and padding to position the border appropriately
    margin-top: 5px;
    margin-bottom: 3px;
    padding-top: 8px;
    padding-left: 5px;
    border-top-style: solid;
    border-top-width: 1px;
    font-size: 1em;
    font-weight: bold;
    line-height: normal;
  }
  > p {
    margin-bottom: 8px;
    padding-left: 5px;
  }
}

.providers-practice_unit-buttons {
  margin-top: -3px;
  margin-left: 1px;
  margin-bottom: 4px;
  font-size: 0.9em;
  display: flex;
  flex-flow: row wrap;

  button {
    margin: 0 4px;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;

    &:first-child {
      margin-left: 0;
    }
  }
}

.disclaimer {
  summary, h5 {
    font-weight: bold;
  }
  summary + * {
    margin-top:5px;
  }
  :last-child {
    margin-bottom: 0;
  }
}

.admin .fa-pencil {
  color: $blue;
}

.user-deletion-warning {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-light;
  border-radius: $card-border-radius;
  padding: 5px 10px;

  .form-group {
    margin-bottom: 0;
    margin-left: 5px;
    white-space: nowrap;
  }
}

.user-tab-error:not(.active):not(.disabled) {
  color: $red !important;
}

.practice-cell {
  &.disabled {
    color: #ccc;
  }
  &.virtual {
    font-weight: bold;
  }
}

.primary-practice-flag {
  color: $blue;
}

// 5.9 Site Settings Page

.site_settings {
  h4, .h4 {
    margin-top: 0.5rem;
  }

  .react-checkbox-tree {
    margin-bottom: 0.5rem;
  }

  .rct-collapse {
    display: none;
  }

  .toggle-field {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    &:last-of-type {
      margin-bottom: 0.6rem; // to account for the extra height of the toggle
    }

    b {
      width: 165px;
      text-align: right;
    }

    & > label {
      margin: 0 5px;
    }

    .greyed_out {
      color: $gray-light;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 1400px) {
    .table_filters {
      flex:1 0 66%;
    }
    .global_toggles {
      flex:1 0 33%;
    }

    .checkbox-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .settings-dropdown {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;

    > label {
      font-weight: bold;
    }

    > .select {
      flex-grow: 1;
      max-width: 350px;
      margin-left: 10px;
    }
  }

  .modules {
    padding-bottom: 0.5rem;

    .form-group {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 0;
      padding: 0.5rem 1rem;

      &:nth-child(2n) {
        background-color: $gray-lightest;
      }

      + * {
        border-top: 1px solid $lightgrey;
      }
    }

    .table-header {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      font-weight: bold;

      .spacer {
        flex-grow: 1;
      }
    }

    p {
      flex: 1 1 120px;
      margin: 0 1rem 0 0;
    }

    .cell, .column-header {
      flex: 0 1 90px;
      text-align: center;
      margin: 0;
    }
    .cell {
      line-height: 0;
    }
    label {
      margin: 0;
      padding: 12px 25px;
    }
  }

  .sticky-bottom {
    display: inline-block;
    position: sticky;
    bottom: 0;
    margin: 0 0 $card-margin-after -15px;
    padding: 15px;
    border: 1px solid darken($lightblue, 10%);
    border-radius: 0 $card-border-radius $card-border-radius 0;
    background-color: $body-bg;
  }
}

// 5.10 Inactive Patients Modal
.inactive-patients {
  .modal-body > .card {
    overflow: auto;
    margin-bottom: 0;
    min-height: 500px;
  }
  .grid-cell-header {
    border-top: none; // bumps up against the container's border
  }

  .btn-blank {
    padding: 6px 8px;

    .fa-user-plus {
      color: $blue;
    }
    .fa-user-times {
      color: $red;
    }
  }

  .active {
    &.evenRow {
      background-color: mix($green, $white, 10%);
    }
    &.oddRow {
      background-color: mix($green, $white, 18%);
    }
    &:hover {
      background-color: mix($green, $white, 30%);
    }
  }
}


// 5.11 Manage Tags Modal

.user-tags {
  $max-table-height: 40px + (35px * 6); // header plus six rows

  h3 {
    margin-bottom: -5px;
  }

  .modal-body {
    background-color: $lightblue;
  }

  .card {
    display: block;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    > .btn {
      margin: 10px;
    }
  }

  .card-header {
    padding: 10px;

    p {
      flex: 1 1 calc(100% - 40px);
      margin: 0;
    }
  }
  .card-header-group {
    gap: 10px;
  }
  .card-placeholder {
    padding: 10px;
  }

  .table-container {
    width: 100%;
    overflow: hidden;
    max-height: $max-table-height;
  }

  .tag-search {
    flex: 1 1 calc(100% - 40px);

    &:focus {
      @include box-shadow($blue);
    }
  }

  .btn-settings {
    margin-left: 10px;
  }

  .loader {
    min-height: 200px;
    max-height: $max-table-height;
  }
  .can-edit .loader {
    min-height: 250px;
    max-height: $max-table-height + 50px;
    margin-bottom: -50px; // account for footer
  }

  .permission_management {
    gap: 10px 0;
    padding: 0 0 10px;
  }
  .permission_column {
    padding: 0 10px;
  }
}

.user-tags-table {
  .fa {
    line-height: normal;
  }

  .btn-blank {
    &[disabled] .fa-save {
      color: $gray-light;
      cursor: not-allowed;
    }
  }

  .checkbox-field .fa {
    margin: 0;
    padding: 6px 7px;
  }

  label, .form-group {
    margin: 0;
    // XXX this is only really for the inputs in the tags table. Might need to be made more specific.

    input {
      height: 32px;
      box-shadow: none !important;
    }

    &.filter_field_error input {
      border: 1px solid $red !important;
    }
  }

  .selectable {
    cursor: pointer;
  }

  .selected {
    background-color: lighten($blue, 25%);

    &.highlight {
      background-color: lighten($blue, 20%);
    }
  }
}

.user-tags-permissions-subheader {
  margin: 10px 10px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;

  > * {
    margin: 0;

    + * {
      margin: 5px 0 0 10px;
    }
  }

  @include is-or-contains(h3) {
    font-size: 1.3rem;
  }
}

.btn-grant-all {
  margin: -5px 0 1rem;
}


// 5.12 Add Patient Modal
.add_patient fieldset {
  margin-bottom: 1rem;
}

.add_patient-group {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }
}

.add_patient-valign {
  .row {
    height: 100%;
    align-items: center;
    align-content: center;
  }
}


// 5.13 Alerts Page

.add_alert-modal-open_button {
  margin-left: auto;
}


// 5.14 AWV Worklist

%counter-header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  gap: 10px 20px;
  margin: 20px 0 10px; // same as h1

  > h1 {
    margin: 0;
  }

  > img {
    align-self: center;
  }
}

.awv-page_header {
  @extend %counter-header;
}

.main-content:has(.awv) {
  max-height: calc(100vh - #{$navbar-height + $banner-height + $card-spacer-y - $card-margin-after});
  min-height: 500px;
}

.awv {
  overflow: hidden;

  .ineligible-cell {
    color: $gray-light;
    font-style: italic;
  }
}


// 5.15 Patient Audits

$audit-input-height: calc(1.5rem + 0.75rem + 2px);

.patient_audits {
  overflow: hidden;

  .main-content:has(&) {
    max-height: calc(100vh - #{$navbar-height + $banner-height + $card-spacer-x - $card-margin-after});
    min-height: 500px;
  }

  .btn-modal {
    .fa {
      color: $blue;
    }
  }
  .btn-modal[disabled] .fa {
    color: $gray-light;
  }
  .btn-status .fa {
    vertical-align: bottom;
    margin-left: 5px;
  }
}

.patient_audit-page_header {
  @extend %counter-header;

  > img {
    margin-right: auto;
  }

  .select__control {
    height: 30px; // to match small buttons
    min-height: 0;
    font-size: 0.9rem;
  }
  .select__value-container {
    padding: 0 8px;
  }
  .select__indicator {
    padding: 4px 8px;
  }
}

.audits-modal {
  h4 {
    font-weight: bold;
  }

  ol, ul {
    padding: 0;
  }
  .card {
    margin-bottom: 0;
    border: 1px solid $gray-lighter;
  }

  .card-body {
    max-height: 40px + 10 * 35px; // header plus 10 rows
  }
}

.audit-files {
  .grid-header {
    border-top: none;
    background-color: transparent;
  }
}

.audit-file-deleted {
  color: $gray-light;
  text-decoration: line-through;
  font-style: italic;

  a {
    color: $gray-light !important;
    font-weight: normal !important;
    cursor: default;
  }
}

.audit-files-new {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 0.5rem;

  h4 {
    flex-basis: 100%;
    margin: 0;
  }

  textarea {
    flex: 10 0 300px;
    width: auto;
    min-height: $audit-input-height;
  }

  .custom-file {
    flex-grow: 1;
    width: auto;
  }

  .audit-file-buttons {
    .btn + .btn {
      margin-left: 0.5rem;
    }
  }

  .btn {
    height: $audit-input-height;
  }
}

.audit-files-warning {
  width: 100%;
  margin: 0;

  @include media-breakpoint-up(lg) {
    order: 100;
  }
}

.audit-note {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0 6px;
  padding: 0.666rem 1rem;

  + .audit-note {
    border-top: 1px solid $card-border-color;
  }

  h4 {
    margin: 0 1rem 3px 0;
    line-height: normal;
  }

  p {
    flex: 1 0 300px;
    margin-bottom: 0;
  }

  textarea {
    flex: 1 0 300px;
    min-height: $audit-input-height;
  }

  label {
    margin: 0;
  }

  .btn {
    height: $audit-input-height;
  }
}

.audit-note-deleted {
  background-color: $gray-lightest;
   h4, p {
     color: $gray-light;
     text-decoration: line-through;
   }
}
.audit-note-important {
  background-color: $translucent-orange;
}

.audit-note-header {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  flex: 0 0 100%;
  gap: 0 6px;

  > :nth-last-child(2) {
    margin-right: auto;
  }
}

.audit-note-buttons {
  align-self: flex-start;

  .btn-blank {
    width: 27px;
    vertical-align: middle;
  }
  .btn-blank-spinner {
    padding: (27px - 16px) * 0.5;
  }
  .fa-edit {
    transform: translateY(1px); // edit icon and trash icon have slightly different vertical alignments
  }

  label {
    vertical-align: middle;
    margin-left: 1rem;
  }
}

.audit-note-date {
  margin-bottom: 2px;
  color: $gray-medium;
}

.card + {
  .audit-files-new, .add-new-note {
    margin-top: 1rem;
  }
}

.audit-note-new {
  display: flex;
  align-items: center;
  width: 100%;
}

.audit-status {
  .modal-footer .btn-primary {
    margin-left: auto;
  }
}

.audit-recent-change {
  border: 1px solid $blue;
  background-color: $translucent-blue; // XXX not sure about this colour
  font-weight: bold;
}


// 6.0 Batch Print

%print-item {
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 5px;
  background-color: #ddd;
  color: #777;

  > span {
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.print-checkbox {
  @extend %print-item;

  &.selected {
    background-color: mix($blue, $white, 80);
    color: black;
  }

  .rf-checkbox {
    display: inline-flex;
    align-items: baseline;

    &:has(.fa-check-square-o) {
      font-weight: bold;
    }

    &:hover {
      color: #333;
    }

    .fa {
      margin-right: 3px;
    }

    &.label-left .fa {
      order: 1;
      margin-left: 3px;
    }
  }

  .small {
    margin: 0;
    color: $gray-dark;
    font-size: 12px;
  }
}

.poc-print-radio {
  @extend %print-item;
  $icon-width: 12px;
  $icon-margin: 4px;

  > span:hover {
    color: #555;
  }

  &.selected {
    background-color: white;
    color: #333;

    > span {
      font-weight: bold;
      &:hover {
        color: #666;
      }
    }
  }

  > span > .fa:first-child {
    width: $icon-width;
    margin-right: $icon-margin;
  }

  > .react-checkbox-tree {
    margin-left: -($icon-width + $icon-margin + 1);

    > p:first-child {
      margin-top: 5px;
    }
  }
}

.batch-print {
  h5 {
    margin: 10px 0;
    font-weight: bold;
  }
  .row > * > h5 {
    margin-top: 0;
  }

  .print-checkbox {
    position: relative;
    margin: 8px 0;
  }

  .selected_patients_list {
    box-sizing: content-box;
    max-height: 325px;
    min-height: calc(37px * 2);

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
  }

  .toggle-field {
    position: absolute;
    right: 10px;
    margin: 0;
    padding: 0;

    &:hover {
      color: #333;
    }

    .fa {
      margin-right: 3px;
    }
  }

  .printing {
    padding: 20px;
    text-align: center;
  }
}

.batch-print-section-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;

  h5 {
    margin: 0;
  }
}

.batch-print-remove_all {
  appearance: none;
  margin: -5px -2px;
  padding: 1px 2px;
  background-color: transparent;
  border: 1px solid transparent;
  text-decoration: underline;
}

.batch-print-together {
  .print-checkbox {
    margin-right: 20px;
    font-weight: bold;

    > span {
      cursor: pointer;
      color: #333;

      &:hover {
        color: #555;
      }
    }
  }

  .fa {
    width: 14px;
    margin: 0 3px;
  }
}

.print-checkboxes {
  @extend %print-item;

  position: relative;
  overflow: hidden;
  margin-top: 10px;
  background-color: white;
  color: $body-color;

  h5 {
    margin-top: 0;
    line-height: 19px;
  }

  .check-all {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    padding: 4px 10px;
  }

  .react-checkbox-tree {
    max-height: 300px;
    overflow: auto;
    margin-left: -17px;
  }
}

.print-linkages {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.print-costs, .print-conditions {
  display: block;
  flex-flow: row nowrap;
  justify-content: space-between;

  .button-group {
    margin: 10px 0 0;
  }
}

.print-field-error {
  &.selected {
    background-color: mix($brand-danger, white, 66%);
  }
  h5 {
    margin-bottom: 8px;
  }
}

.print-checkboxes-row {
  display: flex;
  flex-flow: row wrap;

  > * {
    flex-grow: 1;

    + * {
      margin-left: 8px;
    }
  }
}

// 7.0 Server status page
.server_status {
  max-width: 400px;
  margin: 0 auto 50px;

  dt {
    flex: 0 1 8.5rem;
    padding-right: 0.5rem;
  }

  dd {
    flex: 1 1 auto;
  }

  br {
    content: "";
    width: 100%;
  }

  .card-title {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .card-subtitle {
    display: block;
    margin: -5px 0 0.75rem;
    font-style: italic;
    font-weight: 500;
    text-decoration: underline;
    color: $gray;
  }

  .card-text {
    display: flex;
    flex-flow: row wrap;

    > dd, > dt {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

// 8.0 Report Library

.report-list-column, .report-panel-column {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  max-height: calc(100vh - #{$navbar-height + $banner-height + $card-spacer-x} - 30px - 1.8rem - 1.5rem - 0.75rem);
  min-height: 400px;
}

.report-list {
  overflow: hidden;
  max-height: 100%;

  .card-header {
    padding: $card-spacer-x;
  }
  .report-search {
    margin: $card-spacer-y 0 0;
  }
  .card-body {
    flex: 1 0 0;
    padding: 0;
    overflow: auto;

    > ul {
      margin: 0;
      padding: 0;
    }
    li {
      display: block;
    }
  }
}

.report-panel {
  min-height: 0;
  .card-footer {
    z-index: 1; // not sure why, but top border gets hidden without this
  }
}

.report-library-category {
  $cell-spacing-x: 12px;
  $cell-spacing-y: 8px;
  $cell-padding: $cell-spacing-y * 0.5;

  width: 100%;

  &:focus-within {
    outline: 2px auto black;
    outline-offset: -1px;
  }

  &:nth-child(2n) {
    background-color: $lightgrey;
  }
  &:nth-child(2n+1) {
    background-color: $white;
  }

  button, li {
    width: 100%;
    background-color: transparent;
    text-align: left;
  }
  button {
    padding: $cell-spacing-y $cell-spacing-x;
    font-weight: bold;

    &:focus {
      outline: none;
    }
    .fa {
      margin-left: 5px;
      font-size: 0.9em;
      transition: .35s ease;
    }
  }

  &:has(.collapse.show), &:has(.collapsing) {
    button .fa {
      rotate: 90deg;
    }
  }

  ul {
    margin: 0-$cell-padding 0 0;
    padding: 0 $cell-spacing-x $cell-spacing-y;
  }
  li {
    padding: 0;
  }
  a {
    display: inline-block;
    text-decoration: underline;
    padding: $cell-padding 0;

    &:hover {
      font-weight: bold;
    }
  }
}

.report-name {
  .fa {
    display: inline-block;
    margin: 2px 5px 0;
  }
}

div.table-description {
  // you can specify a different element if you don't want this styling
  padding: 0 15px 10px;
}

// X Footer
#site-footer {
  margin-bottom: $card-margin-after;
  text-align: center;

  small {
    font-size: 12px;
  }

  .datestamp {
    font-weight: 700;
    display: block;
    margin-bottom: 20px;
  }
}


// XXX everything after this is pretty much unsorted and needs to be placed appropriately

.fake-nav-item {
  a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: #262626;
      background-color: #f5f5f5;
    }
  }
}

.bb-region.high {
  fill: red;
}

.bb-region.medium {
  fill: yellow;
}

.bb-region.low {
  fill: green;
}

.bb-tooltip {
  border: 1px solid $gray-lighter;

  caption {
    display: block;
    caption-side: top;
    padding: 3px 6px;
    background-color: white;
    color: $body-color;
    font: 13px $font-family-base;
    font-weight: bold;
  }
}

hr {
  margin: 15px 0;
}

.selectPatients {
  label {
    font-size: 16px;
    margin-bottom: 15px;
  }
  margin: 0;
  .well {
    margin-bottom: 0;
    height: 300px;
    .list-group {
      height: 230px;
      overflow: auto;
    }
    .list-group-item {
      outline: 0;
      span {
        font-size: 12px;
        color: gray;
      }
      &.active {
        color: white;
        background-color: $blue;
        border-color: $blue;
        span {
          color: lighten(gray, 25);
        }
      }
      &:hover {
        background-color: lighten($blue, 15);
        border-color: lighten($blue, 15);
      }
    }
  }
  .selectedItems {
    .list-group-item {
      &:hover {
        background-color: lighten($orange, 15);
        border-color: lighten($orange, 15);
      }
    }
  }
}

.includeModalItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.selectPracticeUnits {
  @extend .selectPatients;
  .well {
    height: 200px;
    .list-group {
      height: 130px;
    }
  }
}

.measure_survey {
  background-color: transparent !important;
  tr {
    border: 1px solid #80baec;
    td {
      border: 0 !important;
    }
  }
}

.selectAllButton {
  background-color: #edf4fc;
  color: black;
  letter-spacing: 1px;
  border-color: $blue;
  &:hover {
    background-color: $blue;
    color: white;
  }
}

.button-as-link {
  background-color: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}

.react-checkbox-tree {
  font-size: 14px;
  label {
    display: flex;
    flex-flow: row nowrap;
    font-weight: normal;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #ddd;
      background-color: #edf4fc;
    }
  }
}

#patientListFilters {
  .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.loader {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 100%;
  min-height: 360px; // arbitrary minimum
}

.stick_to_top {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.patientListTopBtn {
  // box-shadow: 0 1px 10px #888 !important;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #333;
}

.createFilterBtn {
  @extend .patientListTopBtn;
  background-color: $lightgrey;
  width: calc(100% - 120px);
  &:hover {
    background-color: mix($blue, white, 45%);
  }
  &[disabled] {
    background-color: $lightgrey;
  }
}

.saveFilterBtn {
  @extend .patientListTopBtn;
  background-color: mix($orange, white, 25%);
  &:hover {
    background-color: mix($orange, white, 55%);
  }
}

.saveCampaignBtn {
  @extend .patientListTopBtn;
  background-color: mix($green, white, 25%);
  &:hover {
    background-color: mix($green, white, 55%);
  }
}

.disabledSaveFilterBtn {
  @extend .patientListTopBtn;
  background-color: #ddd;
  cursor: default;
}

.invalidSaveFilterBtn {
  @extend .patientListTopBtn;
  background-color: mix($red, white, 25%);
  cursor: default;
}

#modal-datetime {
  .rdtPicker {
    width: 500px;
    td,
    th {
      height: 40px;
    }
    td.rdtActive {
      background-color: $blue !important;
      border-radius: 5px;
    }
  }
}

.system_down_page {
  color: $red;
  a {
    color: $red;
  }
  p {
    text-align: center;
    font-weight: bold;
  }
}

.tcm_enter_data {
  display: flex;
  justify-content: left;
  align-items: baseline;
  span {
    cursor: pointer;
    &:last-child {
      color: $blue;
      margin-left: 15px;
    }
    &:last-child:hover {
      color: darken($blue, 30);
    }
    &:hover {
      font-weight: bold;
    }
  }
}

.tcm_edit_data {
  @extend .tcm_enter_data;
  span {
    margin-left: 15px;
  }
}

.tcm_loading_data {
  @extend .tcm_enter_data;
  img {
    position: relative;
    top: 2px;
    max-height: 1em;
    margin-left: 12px;
  }
}

// #priority-attention {
//     .ReactVirtualized__Table__row {
//         background-color: mix($red, white, 15%);
//         border-bottom: 1px solid mix($red, white, 35%);
//     }
// }

.snackbar {
  color: $gray-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  button {
    background-color: #ff995c;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 15px;
  }
}

.VirtualizedSelectOption {
  cursor: pointer;
}

.tcm_edit_popover_tr {
  th {
    min-width: 100px;
    font-size: 12px;
  }
}

.tcm-popover {
  .spinner-loading {
    position: absolute;
    z-index: -1;
    min-height: 0;

    + table {
      visibility: hidden;
    }
  }
}

.tcm-popover-table {
  .form-group {
    margin-bottom: 2px; // to counter the extra 2px border at the top
  }
}

.category-filters-line {
  display: block;

  &:before {
    content: "● "
  }

  &.single:before {
      content: ""
  }

  &.indent {
    margin-left: 15px;

    &:before {
      content: "○ "
    }
  }
}


.form-group {
  .datepicker {
    input {
      height: 36px;
    }
  }
}

.divider {
  width: 100%;
  border-bottom: 1px solid $blue;
  margin-bottom: 25px;
}

.leftBorderOrange {
  border-left: 1px solid $orange;
}

.childrenSpanGetCommas {
  span {
    &:after {
      content: ", ";
    }
    &:last-child:after {
      content: "";
    }
  }
}

.vertical-hr {
  width: 0;
  min-height: 100%;
  height: auto;
  margin: 0 0 0 -1px;
  border-top: none;
  border-left: 1px solid $blue;
  align-self: stretch;
}

.sortableHelper {
  z-index: 2000;
}

.table_settings {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  margin: 0 auto;
}

.table_settings_item {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 150px;
  min-height: 100px;
  margin: 5px;
  border-radius: 5px;
  background-color: #eee;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  // center the blocks if there aren't enough to fill the container width
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }

  &:hover {
    background-color: mix($blue, $white, 20%);
  }

  &.selected {
    background-color: mix($blue, $white, 70%);

    &.highlight {
      background-color: mix($blue, $white, 50%);
    }
  }

  &.force-selected {
    background-color: mix($blue, $white, 30%);
    cursor: default;
  }

  .drag-handle {
    cursor: all-scroll;
    padding: 5px;
  }

  .filters & {
    border: 1px solid mix($gray-light, $gray-lighter, 50%);
    &.selected {
      border-color: transparent;
    }
  }
}

.color_me_blue {
  color: darken($blue, 10);
  &:hover {
    color: darken($blue, 20);
  }
}

.full-width-modal {
  width: 100%;
}
.modal-xxl {
  width: 1400px;
  max-width: calc(100vw - 2 * #{$modal-dialog-margin-y-sm-up});
}

.oddRow {
  td {
    background-color: #e8e8e8;
  }
}

.grace_period {
  color: #666;
  font-style: italic;
  a {
    color: #666;
  }
}

.removed_row {
  color: #777;
  font-style: italic;
  text-decoration: line-through;

  a {
    color: #777;
  }

  * {
    text-decoration: line-through;
  }
}

#snackbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: auto;

  top: 60px;
  left: 0;
  right: 0;
  z-index: 1100;
  max-height: 130px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  border-radius: 4px;

  background-color: mix($orange, white, 20);
  color: black;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  @include box-shadow(rgba($orange, 0.5));

  &.invalid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $red;
  }

  .warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }

  .saveable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  button {
    margin-left: 25px;
    background-color: $orange;
    color: black;
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 5px 10px;
  }
}

.pastDue {
  background-color: mix($red, white, 20);
  &:hover {
    background-color: mix($red, white, 20);
  }
  &.oddRow {
    background-color: mix($red, $lightgrey, 20);
    &:hover {
      background-color: mix($red, $lightgrey, 20);
    }
  }
}

.read_only_poc_entry {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  color: black;
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.active {
      font-weight: bold;
    }
    &.due {
      color: $red;
    }
  }
}

.patcm_error {
  @include box-shadow($red);
}

.patcm_value {
  @include box-shadow($blue);
}

.input_error_shadow {
  outline: none;
  border-radius: 4px;
  @include box-shadow($red);
}

.input_value_shadow {
  outline: none;
  border-radius: 4px;
  @include box-shadow(darken($green, 10));
}

.custom_scrollbar {
  width: 100%;
  height: 30px;
  background-color: mix($blue, white, 25);
  position: absolute;
  bottom: -30px;
  line-height: 30px;
  padding-left: 15px;
}

.Select-menu-outer {
  z-index: 21 !important;
}

.undo_button {
  color: black;
  background-color: lighten($orange, 10);

  &:hover {
    color: white;
    background-color: $orange;
  }
  &:active {
    color: black;
    background-color: lighten($orange, 10);
  }
}

.poc-measure-history {
  max-height: 300px;

  @include media-breakpoint-down(sm) {
    width: 650px;
  }
  @include media-breakpoint-up(md) {
    width: 750px;
  }
  @include media-breakpoint-up(lg) {
    width: 850px;
  }

  .spinner-loading, .ler-empty {
    min-height: 100px;
  }

  table {
    width: 100%;
  }
  th,
  td {
    width: 16.6%;
  }
}

.historical_row {
  td {
    vertical-align: middle !important;

    &.error {
      color: $red;
    }
    input {
      width: 100%;
      &.error {
        outline: $red auto 5px;
      }
    }

    span.fa {
      cursor: pointer;
      font-size: 14px;

      &:first-child {
        margin-right: 10px;
      }
    }

    .save {
      cursor: pointer;
    }
  }

  .comment-field {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .edit-measure-select {
    height: 23px;
  }
  .edit-measure-multiselect {
    width: 250px;
  }

  .edit-measure-composite {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 5px;
  }
}
.historical_row-buttons {
  white-space: nowrap;
}

#patient-list input.form-control {
  height: 35px;
}

.selected_patients_list {
  background-color: white;
  min-height: 200px;
  max-height: 300px;
  overflow: auto;

  div {
    padding: 8px;
    vertical-align: middle;
    &:nth-child(odd) {
      background-color: #e8e8e8;
    }
    span {
      display: inline-block;
      &:first-child {
        width: calc(100% - 16px);
      }
      &:last-child {
        line-height: 1.5;
        float: right;
        text-align: center;
        color: red;
        cursor: pointer;
      }
    }
  }
}

.survey-measure {
  .survey-toggle {
    line-height: 36px;
    color: #4b8fdd;
    cursor: pointer;
  }

  &.disabled .survey-toggle {
    color: $gray-light;
    cursor: default;
  }

  .form-group {
    width: calc(100% - 20px);
    float: right;
  }

  .survey {
    margin: 30px -200px 30px -240px;
    background-color: white;
    border: 1px solid $blue;

    p.survey-title {
      margin: 0;
      padding: 20px;
      font-weight: bold;
    }

    .survey-question {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 15px;
      width: 100%;
      padding: 10px;
      border-top: 1px solid mix($blue, white, 30);

      > * {
        flex: 0 1 50%;
      }
    }
  }
}

.campaign-heading {
  background-color: mix($blue, white, 20) !important;
}

.campaign-permissions {
  background-color: mix($blue, white, 15%);
  padding: 15px;

  .overflow_container {
    max-height: 200px;
    overflow: auto;
  }
}

.permission_management {
  margin: 0;

  > * {
    padding: 0;
  }
}

.campaign-permissions .permission_management {
  margin: 0 calc(15px / 2);

  > * {
    padding: 0 calc(15px / 2) 15px;
  }
}

.permission_column {
  border-radius: 4px;
  background-color: white;
  padding: 10px;

  .overflow_container {
    max-height: 200px;
    overflow: auto;
  }
}

.permission_column-header {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-flow: row wrap;
  margin: -5px -10px 5px 0;

  .search-bar {
    flex: 1 1 150px;
    height: unset;
    margin: 5px 10px 5px 0;
    padding: 0;

    input {
      height: unset;
      margin: 0;
      font-weight: normal;
    }

    .btn-spacer {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .fa {
      margin: 0;
    }
  }

  h4 {
    flex: 1 1 auto;
    font-weight: bold;
    line-height: 35px;
    margin: 5px 10px 0 0;
  }

  .fa {
    margin-right: 10px;
  }
}

.permission_column-header-buttons {
  display: flex;
  flex-flow: row wrap;

  > * {
    height: 33.5px;
    box-sizing: border-box;
    margin: 5px 10px 5px 0;
  }

  button {
    flex: 0 1 auto;
    white-space: nowrap;

    .fa {
      color: inherit;
      margin-right: 5px;
    }
  }
}



@keyframes fade-blue-clear {
  from {background-color: $translucent-blue;}
  to {background-color: transparent;}
}
@keyframes fade-red-clear {
  from {background-color: $translucent-red;}
  to {background-color: transparent;}
}

.permission-item {
  &:nth-child(even) {
    background-color: white;

  }
  &:nth-child(odd) {
    background-color: $lightgrey;
  }
}

.permission-item-inner {
  display: flex;
  flex-flow: row nowrap;
  padding: 5px;
  gap: 5px;
}
.permission-item-saving {
  background-color: $translucent-blue;
}
.permission-item-saved {
  animation: fade-blue-clear 3s linear;
}
.permission-item-error {
    animation: fade-red-clear 5s linear;
}

.permission-item-label {
  flex-grow: 1;
}
.permission-item-button {
  flex: 0 0 auto;
  align-self: stretch;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  margin: -2px 0;
  padding: 0 8px;
}
.permission-item-spinner {
  flex: 0 0 100px;
  align-self: center;
  text-align: center;
}

.bar-indicator {
  width: 95%;
  background-color: #bbb;
  color: white;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: unset;

  div {
    display: inline-block;
    padding: 1px 0;
    text-align: right;
    overflow: visible;
    border-radius: 4px;
    &.current_rate {
      position: relative;
      z-index: 1;
      background-color: $red;
      &.success {
        background-color: $green;
      }
    }
    &.target_rate {
      z-index: 0;
      overflow: visible;
      background-color: #777;
    }
    &.deadspace {
      color: transparent;
      background-color: transparent;
    }
    &.money_earned {
      position: relative;
      overflow: visible;
      background-color: $green;
      z-index: 1;
    }
    &.money_left {
      background-color: mix($blue, white, 92);
      z-index: 0;
    }
    span {
      position: relative;
      margin: 0 5px;
    }
  }
}

.cost-indicator {
  $border-radius: 4px;
  $local-line-height: 1.5rem;

  position: relative;
  width: 95%;
  height: $local-line-height;
  border-radius: $border-radius;
  overflow: hidden;
  line-height: $local-line-height;
  font-size: 0.9rem;
  color: white;

  .bar {
    position: absolute;
    display: inline-block;
    height: 100%;
    left: 0;
    padding-right: $border-radius;
    border-radius: $border-radius;
    text-align: right;
    overflow: visible;
  }
  .cost {
    $cost-height: 1.1rem;
    $cost-margin: ($local-line-height - $cost-height) / 2;

    z-index: 1;
    height: $cost-height;
    top: $cost-margin;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    line-height: 1.3; // arbitrary but it looks fine

    background-color: $red;
    &.success {
      background-color: $green;
    }
  }
  .allocation {
    z-index: 0;
    padding-top: 1px;
    overflow: visible;
    background-color: scale-color(#777777, $alpha: -40%, $lightness:-70%) // this transform maintains the same apparent color even with the reduced opacity
  }

  .numbers {
    position: relative;
    display: flex;
    width: 100%;
    z-index: 2;
    justify-content: space-between;

    > * {
      position: relative;
      top: 1px; // fixes vertical alignment
      text-align: right;
      padding: 0 $border-radius;
    }
  }
}
.cost-indicator-tooltip .tooltip-inner {
  max-width: 250px;
}

.dashboard-table {
  .dashboard-table-controls {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px 0; // normally we would want a horizontal gap too, but we're already using margins and I don't want to mess with that right now.
  }
  .dashboard-table-title {
    flex-grow: 1; // lets this look left-aligned
  }
  .card-title a {
    text-decoration: underline;
  }
  .filter-status {
    margin-top: 8px;
    ul {
      margin-left: -5px;
    }
  }
  .filter-button {
    display: inline-block;
    float: none;
  }
}

.button-group {
  display: flex;
  margin: -3px 15px -3px 0;

  button, .btn {
    background-color: white;
    color: darken($blue, 10);
    flex: 1;
    border-radius: 1px;
    border: 1px solid #bbb;
    border-right: 0;
    line-height: 1;
    font-size: 14px;
    padding: 6px 12px;
    white-space: nowrap;
    text-transform: none; // these don't need to be uppercase

    &[disabled] {
      &, &:hover {
        color: $gray-medium;
      }
    }

    &:not([disabled]).selected {
      color: white;
      background-color: darken($blue, 10);
    }
    &:last-child {
      border-right: 1px solid #bbb;
    }
  }
}

.large-modal {
  width: 95%;
  max-width: none;

  @include media-breakpoint-up(md) {
    min-width: 768px;
  }
  @include media-breakpoint-down(xs) {
    width: auto;
  }
}

.navigation-hot-fix {
  .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.clickable-icon {
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 0 !important;
  color: darken($blue, 5);

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:hover {
    color: darken($blue, 20);
  }
}

.print-icon {
  @extend .fa-print;
}

.export-icon {
  @extend .fa-external-link;
}

.not_a_member {
  color: #666;
  font-style: italic;
  &:hover {
    background-color: #ccc;
  }
}

.add_member_button {
  background-color: lighten($green, 40);
}

.remove_member_button {
  background-color: lighten($red, 40);
}

.filter-button {
  font-size: 16px;
  margin: -5px 5px -6px 5px;
  color: darken($blue, 10);
  background-color: white;
  padding: 4px 10px;

  &:hover {
    background-color: darken($blue, 10);
    color: white;
  }
  &.invalidForm {
    cursor: not-allowed;
    background-color: #eee;
    color: #666;
  }
  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
  }
}

.filter-cancel-button {
  @extend .filter-button;
  margin-left: 0;
  border-left: none;
  color: #666;
  &:hover {
    color: #333;
    background-color: #ccc;
  }
}

.orange-highlight {
  height: 100%;
  background-color: fade-out(mix($orange, white, 20%), 0.4);
  padding-left: 8px;
}

.backgroundBlue {
  background-color: mix($blue, white, 15%) !important;
}

.grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.input-group-append .input-group-text {
  // used to be:
  //width: 68px;
  //max-width: 68px;
  min-width: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  justify-content: center;
}

.input-group {
  width: 100%;
}

@include media-breakpoint-down(md) {
  .compress-font {
    font-size: 13px; // Hack for sizing POC on tablet
  }
}

.ReactTable .rt-thead {
  overflow-y: overlay;
  overflow-x: hidden;
}

.ReactTable .rt-tbody {
  overflow-y: overlay !important;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}


  .poc_campaigns_layout {
  gap: $card-spacer-x 0;

  > div {
    max-height: 100%;
  }

  .card {
    margin-bottom: 0;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    > div {
      &:first-child {
        padding-right: $card-spacer-x * 0.5;
      }
      &:last-child {
        padding-left: $card-spacer-x * 0.5;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #poc-tab-container {
    ul > li > a {
      font-size: 14px;
    }
  }
}

.fa {
  &.indicator-info {
    @extend .fa-info-circle;
    color: $blue;
  }
  &.indicator-no {
    @extend .fa-times-circle;
    color: $red;
  }
  &.indicator-yes {
    @extend .fa-check-circle;
    color: $green;
  }
  &.indicator-pending {
    @extend .fa-question-circle;
    color: darken($yellow, 5%);
  }
  &.indicator-overdue {
    @extend .fa-exclamation-circle;
    margin-left: 5px;
    color: $red;
  }
  &.indicator-not_a_member {
    @extend .fa-exclamation-circle; // XXX maybe not this icon?
    margin-left: 5px;
    color: $orange;
  }
  &.indicator-ed {
    @extend .fa-plus-square;
    color: $red;
  }
  &.indicator-hospital {
    @extend .fa-h-square;
    color: #0074d9;
  }
  &.indicator-grace_period {
    @extend .fa-clock-o;
    margin-left: 5px;
    color: $orange;
  }
  &.indicator-deceased {
    @extend .fa-minus-circle;
    margin-left: 5px;
    color: #777;
  }
  &.indicator-risk:not(:last-child) {
    // icon and color assigned db-side
    margin-right: 2px;
  }
  &.indicator-billable {
    @extend .fa-circle;
    color: #61b329;
    font-size: 8px;
    margin-left: 8px;
    vertical-align: middle;
  }
  &.tcm-edit {
    color: $blue;
    cursor: pointer;
  }
  &.fa-undo {
    cursor: pointer;
  }
  &.cancel-measure-edit,
  &.fa-trash-o,
  &.fa-user-times,
  &.fa-close {
    color: $red;
    cursor: pointer;
  }
  &.alert-close {
    @extend .fa-close;
    color: #ffffff;
  }
  &.fa-cancel {
    @extend .fa-close;
    color: black;
  }
  &.fa-edit {
    color: $blue;
  }
  &.disabled {
    cursor: default !important;
    color: $gray-light !important;
  }
}

img.indicator-risk {
  height: 2rem;

  &.fallback {
    height: 1rem;
  }
}

.community_linkages .card-body {
  background-color: $lightblue;

  @include media-breakpoint-up(xl) {
    overflow: auto;
  }
}
.community_linkages-update {
  width: 100%;

  table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0 !important;

    > tbody > tr {
      > th,
      > td {
        width: 20%;
        border: none !important;
        padding-bottom: 0;
        vertical-align: middle;

        &.community_linkage_date {
          width: 160px !important;

          input {
            height: 36px;
          }
        }
      }
    }

    tr > :first-child {
      padding-left: 1px;
    }
    tr > :last-child {
      width: 80px;
    }
  }

  .Select-clear {
    height: 34px; // only found in ClearButton, in a 36px-high ButtonField
  }

  .popup-history {
    &:hover,
    &:focus {
      outline: none;
    }
  }
  .popup-history i {
    color: $blue;
  }
}

.add_community_linkage {
  label {
    font-weight: bold;
    padding-top: 8px;
  }

  .add_community_linkage_comment {
    textarea {
      height: 75px;
    }
  }
}

.icon-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  height: inherit;
  padding: 0;
  border: 0;
  background: transparent;

  &:focus {
    outline: none;
  }
  &[disabled] {
    color: $gray-light;
  }
}

#community_linkage_history_popover {
  width: 600px;

  .spinner-loading, .ler-empty {
    min-height: 100px;
  }

  table {
    width: 100%;
  }
  th,
  td {
    width: 20%;
  }
}

.button-field {
  height: 36px;
  width: 100%;
  background: white;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  position: relative;
  color: rgb(170, 170, 170);

  &.selected {
    color: black;
  }

  > button {
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: none !important;
    background-color: transparent !important;
    text-align: left;
    color: inherit;
  }

  & > div {
    & > span:first-child {
      vertical-align: middle;
      padding-right: 15px;
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.checkbox-field {
  cursor: pointer;

  &.disabled {
    color: $gray-medium;
    cursor: default;
  }

  input {
    display: none;
  }
  .fa {
    margin: 0 5px;
  }
}

.filled_field {
  @include box-shadow($blue);
  outline: none;
}

.locations-modal {
  display: flex;
  flex-flow: column nowrap;
  max-height: calc(100vh - 3.5rem);
  max-width: 1225px;
  width: 100%;

  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  .modal-body {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    padding: 0;
    min-width: 460px;

    .card-table {
      overflow: hidden;
      margin-bottom: 0;
    }
  }
}

.location-controls {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;

  button.btn {
    text-transform: unset;
    font-weight: unset;
    font-size: 14px;
    white-space: nowrap;
    order: -1;
  }
  > .pull-right {
    order: -2;
  }
}

.location-filters {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;

  > * {
    flex: 1 0 200px;
    margin: 0;
  }

  .search-bar {
    input {
      height: 36px;
    }
    .fa-search {
      line-height: 1.5; // to get the vertical centering looking better
    }
  }
}

.location-buttons {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;
}

.location-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}

.location-action-edit {
  cursor: pointer;
  padding-right: 5px;
}

.datepicker {
  display: block !important;
  position: relative;
  white-space: nowrap;
  width: 100%;
  min-width: 138px;
  font-family: Heebo, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;

  .hoverlay-wrapper {
    position: absolute;
    right: 0;
    top: 2px; // account for the input's border
  }

  > div, > input, .react-datepicker-wrapper, .react-datepicker__input-container {
    display: inline-block; // keeps the button lined up properly
    width: 100%;
  }

  .Select-clear-zone,
  .input-group-text {
    position: absolute;
    cursor: pointer;
    top: 0;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-group-text {
    right: 0;
    background-color: white;
    border-left-width: 0;
    color: $blue;

    &:last-child {
      width: unset;
    }
  }

  .form-control {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:focus, &:focus-within {
      @include box-shadow($blue);
      border-color: $blue;
    }

    &.invalid {
      border-color: $red;
      &:focus {
        @include box-shadow($red);
      }
    }

    &[readonly] {
      background-color: white;
    }
  }

  .datepicker-inputs {
    display: flex;
    flex-flow: row nowrap;
    gap: 1px;
    padding: 0 33px 0 0; // 3px less than the total width of .datepicker-button

    input {
      box-sizing: content-box;
      flex: 1 1 auto;
      min-width: 0;
      padding: 0 0 0 0.25rem;
      border: none !important;
      outline: none;
      //outline-width: 1px;

      &:first-of-type {
        padding-left: 0.75rem;
      }
    }
  }

  input.datepicker-input-date {
    min-width: 90px;
    text-align: center;
  }
  input.datepicker-input-time {
    min-width: 43px;
    flex: 1 1 50px;
  }

  &.disabled {
    cursor: default;

    input,
    .Select-clear-zone,
    .input-group-text {
      cursor: default;
      background-color: $input-disabled-bg;
    }
  }

  .rdtPicker {
    td, th {
      padding: 0;
      border: none;
      vertical-align: middle;
    }
    thead tr:first-child {
      border-bottom: 1px solid #f9f9f9;
    }
  }

  .datepicker-placeholder {
    &:after {
      color: $gray-light;
      content: "MM/DD/YYYY";
    }
  }
}
.rdtPicker.rdtBreakout {
  position: fixed;
}
.rdtBreakoutContainer .rdtOpen .rdtPicker {
  // this rdtPicker is empty, and the real rdtPicker is rendered elsewhere
  display: none;
}

.datepicker-button {
  position: relative;
  padding: 9px 12px;
  font-size: 14px;
  cursor: pointer;

  &.select-clear-date {
    color: #cc0033;
  }

  &.select-today {
    color: #4b8fdd;

    &::after {
      // XXX under construction
      content: "\25a0"; // black square
      font-size: 0.5em;
      position: absolute;
      right: 17.75px;
      top: 13.25px;
    }
  }

  .disabled & {
    color: $gray-light;
    cursor: default;
  }
}

.datepicker-loading {
  display: inline-block;
  position: relative;
  transform: translateX(-28px); // compare to offset of .datepicker-button
}

.datepicker-cell {
  // space to show the input focus box-shadow's blur
  width: calc(100% - 5px);

  .form-control {
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .hoverlay-wrapper {
    top: 0;
  }
  .datepicker-button {
    padding-top: 8px; // the input is a tad smaller

    &.select-clear-date {
      padding-right: 11px;
    }
    &.select-today::after {
      top: 12.25px;
    }
  }
}

.oddRow .rdtPicker td {
  // there's a rule somewhere that sets .oddRow td's background color, which is a problem when you have subtables like in the datepicker.
  background-color: inherit;
}

.Select-clear-zone {
  position: absolute;
  cursor: pointer;
  color: hsl(0,0,80%);
  font-size: 24px;

  &:hover {
    color: hsl(0,0,60%);
  }
}

.measure-select {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin: -7px 0;

  label {
    margin-right: 10px;
    &:after {
      content: ":";
    }
  }

  .select, .Select { // .Select is probably unused at this point, but just in case
    width: 300px;
  }
}

.scrollable-table-container {
  //height: 300px;
  overflow: auto;
  position: relative;

  th { // you can't sticky a thead, but you can sticky a th
    position: sticky;
    top: 0;
  }
}

.outreach-dashboard {
  a {
    text-decoration: underline;
  }

  section {
    text-align: center;
    margin: 0;

    table {
      width: 100%;
      text-align: left;

      tr :last-child {
        text-align: right;
      }
    }
  }

  .filter-status {
    display: block;
  }

  .card-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow-x: auto;
    padding: 20px 0 !important;

    > div {
      min-width: 300px;
      padding: 0 1%;
      flex: 1;

      + div {
        border-left: 1px solid #ddd;
      }

      > h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 2em;
        margin-bottom: 8px;
        text-align: center;
      }
    }

    .progress-bar {
      width: 100%;
      height: 23px;
      border-radius: 4px;
      background-color: #bbb;

      div {
        height: 23px;
        border-radius: 4px;
      }
    }

    .progress-description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px;
    }
  }
}

.deceased-cell {
  color: $red;
  font-weight: bold;
}

tr.patient-info-deceased {
  td {
    color: white;
    background-color: $red !important;
  }
}

.patient-list-deceased {
  font-style: italic;
  text-decoration: line-through;
  color: #777 !important;

  a {
    color: #777 !important;
  }
}

.patient-list-grace_period {
  font-style: italic;
  color: #777 !important;

  a {
    color: #777 !important;
  }
}
