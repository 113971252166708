@import "../../../assets/sass/components/base";

.Table {
  width: 100%;
}

.evenRow {
  background-color: white;
}
.oddRow {
  background-color: $lightgrey;
}

.highlight:not(.removed_row) {
  background-color: $pale-blue;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.ReactVirtualized__Grid {
  outline: 0;
  font-family: Heebo, sans-serif;
  font-size: 14px;

  a {
    text-decoration: underline;
    outline: none;
    &:hover {
      font-weight: 700;
    }
  }
}

.noScrollBar {
  .ReactVirtualized__Grid {
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}


// draggables for column resizing

.react-draggable {
  position: absolute;
  right: -4px; // to make the line appear in the last pixel of the element body
  top: -1px;
  bottom: 0;
  z-index: 100;
  padding: $table-cell-padding 5px;
  cursor: col-resize;

  .grid-cell-header:hover &::after {
    content: "";
    display: block;
    height: 100%;
    border-right: 2px solid $lightgrey;
  }
}

.column-list-spacer {
  flex-shrink: 0;
  width: 1px;
  height: 100px;
  margin: 0;
  padding: 0.5px;
  border: 15px solid transparent;
  background-color: $gray-lighter;
  background-clip: padding-box;
}


// multigrid

$first-cell-padding: 14px;

@mixin hide-scrollbar {
  // hide the fixed columns' and rows' scrollbar. We want them to be scrollable, but we don't need a second scrollbar.
  -ms-overflow-style: none;
    scrollbar-width: none;&::-webkit-scrollbar {
    display: none;
  }
}
@mixin scroll-sync {
  // XXX turning off `will-change: transform` makes the columns scroll in proper sync, but also makes scrolling much choppier (at least in Chrome). Figure out how to handle this better.
  will-change: auto !important;
}

.grid-wrapper {
  flex: 1 1 auto;
  width: 100%;
  // overflow: hidden;
}

.grid-cell {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  min-width: 0;
  margin: 0;
  padding: 0 4px;

  &.first {
    padding-left: $first-cell-padding;
  }

  .hoverlay-wrapper {
    @include ellipsis-overflow;
    // If you're using a hoverlay in a cell renderer and it doesn't use .hoverlay-wrapper, you'll need to @include ellipsis-overflow on the child's outermost element.
  }
}

.grid-cell-inner {
  @include ellipsis-overflow;
  flex-grow: 1;
  position: relative;

  > .hoverlay-wrapper:only-child:not(.cell-inner-ellipsis) {
    display: block;
  }

  .fa-pencil {
    color: $blue;
  }

  a:not([href]) {
    // bootstrap removes underlines from <a>s without an href
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cell-inner-ellipsis {
  display: flex;
  align-items: center;
  width: 100%;

  > * {
    @include ellipsis-overflow;
  }
}

.grid-cell-header {
  position: relative;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: $table-cell-padding $table-sorter-padding $table-cell-padding 4px;
  outline: 0;
  text-transform: none;

  &.first {
    margin-left: 0;
    padding-left: $first-cell-padding;
  }

  // sort arrows

  &.sort, &.sort_asc, &.sort_desc {
    cursor: pointer;

    //noinspection CssNoGenericFontName
    &:after {
      position: absolute;
      bottom: 15px;
      right: 8px;
      display: block;
      font-family: "FontAwesome";
      opacity: 0.5;
    }
  }

  &.sort:after {
    content: "\f0dc" !important;
    font-size: 0.8em;
    padding-top: 0.12em;
    opacity: 0.2;
  }
  &.sort_asc:after {
    content: "\f0de" !important;
    font-size: 1em;
    opacity: 0.5;
  }
  &.sort_desc:after {
    content: "\f0dd" !important;
    font-size: 1em;
    opacity: 0.5;
  }
}

.grid-cell-header-spacer {
  width: 10px;
  margin: 0 !important;
}

.grid-header {
  @include hide-scrollbar;
  @include scroll-sync;

  border-top: 1px solid #ccc;
  text-transform: none;
  border-bottom: 2px solid #ddd;
  background-color: white;

  > .ReactVirtualized__Grid__innerScrollContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-weight: 700;
  }
}

.grid-body {
  @include scroll-sync;
}

.grid-frozen {
  @include hide-scrollbar;
  border-right: 1px solid $gray-lighter;

  .last:not(.grid-cell-header):not(.column-right):not(.column-center) {
    padding-right: 10px;
  }
}

// tiered tables

@mixin normalize-color($color, $offset-l:0%, $offset-v:0%, $importance:null) {
  background-color: change-color($color,
  $lightness: 80% + $offset-l,
  $saturation:50% + $offset-v) $importance;

  &.branch.highlight {
    background-color: change-color($color,
    $lightness: 70% + $offset-l,
    $saturation:80% + $offset-v) $importance;
  }
}

.tiered-table {
  .indent-cell, .arrow-cell {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    a {
      color: inherit;
    }
  }

  .branch {
    cursor: pointer;

    .indent-cell, .arrow-cell {
      &:not(:empty):after {
        content: '';
        flex-shrink: 0;
        width: 0;
        height: 0;
        margin-left: 10px;
        transform: rotate(-90deg);
        border-left: 5.04px solid transparent;
        border-right: 5.04px solid transparent;
        border-top: 7px solid rgba(0, 0, 0, 0.8);
        transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
        cursor: pointer;
      }
    }

    &.expanded {
      .indent-cell, .arrow-cell {
        &:after {
          transform: rotate(0);
        }
      }
    }
  }

  @for $depth from 0 through 3 {
    .row-depth-#{$depth} {
      .indent-cell {
        padding-left: #{$depth}em;
      }

      @for $depth2 from 0 through $depth - 1 {
        .hide-over-depth-#{$depth2} {
          visibility: hidden;
        }
      }
    }
  }

  .row-depth-0, .row-depth-1 {
    font-weight: bold;
  }

  .row-level-0 {
    &.evenRow, &.evenRow:hover {
      background-color: $white;
    }
    &.oddRow, &.oddRow:hover {
      background-color: $lightgrey;
    }
  }
  .row-level-1 {
    @include normalize-color($lightblue, 9%);

    &.evenRow {
      @include normalize-color($lightblue, 5%);
    }
  }
  .row-level-2 {
    @include normalize-color($lightblue, -5%);

    &.evenRow {
      @include normalize-color($lightblue, -9%);
    }
  }
  .row-level-3 {
    color: $white;
    @include normalize-color($lightblue, -20%);

    &.evenRow {
      @include normalize-color($lightblue, -24%);
    }
    .indent-cell, .arrow-cell {
      &:after {
        border-top: 7px solid rgba(255, 255, 255, 0.8);
      }
    }
  }
  .row-level-4 {
    color: $white;
    @include normalize-color($lightblue, -40%);

    &.evenRow {
      @include normalize-color($lightblue, -44%);
    }
    .indent-cell, .arrow-cell {
      &:after {
        border-top: 7px solid rgba(255, 255, 255, 1);
      }
    }
  }

  .row-depth-0 {
    background-color: $gray-medium !important;
    color: $white !important;
  }

  .row-category-blue {
    @include normalize-color($blue, $importance: !important);
  }
  .row-category-orange {
    @include normalize-color($orange,0%,20%, !important);
  }
  .row-category-green {
    @include normalize-color($green,-5%,-10%, !important);
  }
  .row-category-red {
    @include normalize-color($red, $importance: !important);
  }
  .row-category-purple {
    @include normalize-color(purple, 0%, -10%, $importance: !important);
  }
}


// column classes

.column-right {
  text-align: right;
  justify-content: right;
  padding-right: 22px;

  > .grid-cell-inner {
    display: flex;
    text-align: right;
    justify-content: right;
  }
}  // no .column-left class because that's the default behaviour

.column-center {
  text-align: center;
  justify-content: center;
  padding-right: 22px;

  > .grid-cell-inner {
    display: flex;
    text-align: center;
    justify-content: center;
  }
}

.no-sort {
  padding-right: 4px;
}
