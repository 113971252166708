// MODULE.scss
@use 'sass:color';
@import 'base';
@import "../../../../node_modules/bootstrap/scss/tooltip";

// normalization

a {
  cursor: pointer;
}

// font-awesome

.fa {
  min-width: 12px;
}

// Buttons

.bt, .btn {
  font-family: "Heebo", "Helvetica Neue, Helvetica, Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: inherit;
  cursor: pointer;
  outline: 0;
  outline-offset: 0;
  border-radius: 4px;
  -o-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;

  &[disabled], &.disabled {
    cursor: not-allowed;
  }
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus,
.btn.active:focus {
  outline: 0;
  outline-offset: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-sm {
  padding: 5px 10px;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  text-transform: none;
}

.btn-link {
  color: rgba(0, 0, 0, 0.87);
}

.btn-blank {
  padding: 6px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  line-height: 14px;

  .fa-save {
    padding: 0 1px;
    color: $blue;
  }
  .fa-times {
    padding: 0 2px;
    color: $red;
  }

  &:not([disabled]) {
    &:hover, &:active {
      border: none;
      background-color: rgba(0, 0, 0, 0.15);

      .fa-edit, .fa-save {
        color: darken($blue, 25%);
      }
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.25);
    }

    &:focus {
      outline: none;
    }
  }
}

.btn-default {
  background-color: $white;
  border-color: #ccc;
  color: $gray-dark;
  text-transform: none;

  &:not([disabled]) {
    &.active, &:active, &:focus, &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}

.btn-default,
.btn-link {
  &:not([disabled]) {
    &:hover, &:focus {
      color: #2d9bc1;
      opacity: 1;
    }
  }
}

.btn-primary-orange {
  background-color: $orange;
  color: #333;
  &:hover,
  &:focus {
    background-color: $secondary-color-darker;
    opacity: 1;
  }
}

@mixin button-hover($color, $hover-amount:75%, $active-amount:55%) {
  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      $hover-color: mix($color, black, $hover-amount);
      background-color: $hover-color;
      border-color: transparent;
      color: color-yiq($hover-color);
    }
    &:active {
      $active-color: mix($color, black, $active-amount);
      background-color: $active-color;
      border-color: transparent;
      color: color-yiq($active-color);
    }
  }
}

%btn-base {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-color: transparent;
}

@mixin solid-button($color){
  @extend %btn-base;
  background-color: $color;
  color: color-yiq($color);
  @include button-hover($color);
}

@mixin outline-button($color){
  @extend %btn-base;
  border-color: $color;
  color: $color;
  @include button-hover($color);
}


.btn-primary {
  @include solid-button($blue);
}

.btn-secondary {
  @extend %btn-base;
  color: $gray-dark;
  background-color: $gray-lighter;

  &:hover,
  &:focus,
  &:active {
    background-color: $gray-dark;
    border-color: transparent;
    color: white;
  }
}

.btn-danger {
  @include solid-button($red);
}
.btn-warning {
  @include solid-button($warning);
}
.btn-success {
  @include solid-button($success);
}

.btn-light {
  background-color: $gray-lightest;
  border-color: $gray-lightest;
  color: $gray-dark;
}


.btn-outline-primary {
  @include outline-button($blue);
}
.btn-outline-secondary {
  @include outline-button($gray-light);
  color: $gray-medium;
}
.btn-outline-danger {
  @include outline-button($red);
}
.btn-outline-warning {
  @include outline-button($warning);
  color: darken($warning, 15%); // the yellow is a bit too light
}
.btn-outline-info {
  @include outline-button($info);
}
.btn-outline-success {
  @include outline-button($success);
}
.btn-outline-dark {
  @include outline-button($black);
}
.btn-outline-light {
  @include outline-button($white);

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0,0,0,0.3);
      border-color: $white;
      color: $white;
    }
  }
}

.btn-group-counters {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  gap: 2px; // note that all the btns after the first have margin-left:-1px from bootstrap

  .btn {
    white-space: nowrap;
    border-width: 2px;
    border-radius: $border-radius-lg;

    &.active {
      border-color: transparent !important;
    }

    &:focus {
      z-index: 10;
    }
  }
}

// Tooltips

@mixin tooltip($color, $side:bottom) {
  &.bs-popover-#{$side} {
    background-color: $color;

    .arrow::after {
      border-#{$side}-color: $color !important;
    }
  }
  &.bs-tooltip-#{$side} {
    .tooltip-inner {
      background-color: $color;
    }

    .arrow::before {
      border-#{$side}-color: $color !important;
    }
  }
}

.tooltip {
  --tooltip-color: #{$black};
  --tooltip-text: #{$white};
  --tooltip-dividers: #{$gray};


  .tooltip-inner {
    font-size: $tooltip-font-size;
    font-weight: $tooltip-font-weight;
    padding: 5px;
    color: var(--tooltip-text);
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: $gray-lighter;
    }
  }
}


@each $side in [right left top bottom] {
  .tooltip {
    @include tooltip(var(--tooltip-color), $side)
  }
}
.tooltip-blue {
  --tooltip-color: #{$blue};
  --tooltip-dividers: #{$lightgrey};
}
.tooltip-green {
  --tooltip-color: #{$green};
}
.tooltip-darkblue {
  --tooltip-color: #{darken($blue, 30)};
}
.tooltip-red {
  --tooltip-color: #{$red};
}
.tooltip-orange {
  --tooltip-color: #{$orange};
}
.tooltip-grey {
  --tooltip-color: #777;
}

.tooltip-list {
  .tooltip-inner {
    padding: 0 5px;
    max-width: 300px;
    text-align: left;
  }

  ul {
    margin: -2px 2px;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 4px 2px 2px;

    + li {
      border-top: 1px solid var(--tooltip-dividers);
    }
  }
}

.tooltip-list-multicolor {
  .tooltip-inner {
    overflow: hidden;
    max-width: 300px;
    padding: 0;
    text-align: left;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    padding: 6px 8px 4px;
  }
}

.bs-tooltip-bottom-start {
  @extend .bs-tooltip-bottom;

  .arrow {
    left: 20px !important;
  }
}

.tooltip-light_text .tooltip-inner {
  font-weight: 100;

  b, strong {
    font-weight: bold;
  }
}

.tooltip-header {
  //margin: -5px -5px 2px;
  margin-bottom: 2px;
  padding: 4px 2px 4px;
  border-bottom: 1px solid var(--tooltip-dividers);
  font-weight: bold;
  font-size: 1.1em;
}

.hoverlay-wrapper {
  pointer-events: auto;
}

// Custom Popovers

.historyPopup {
  display: none;
  float: right;
}

.plDuePopup {
  width: 600px;
  td,
  th {
    width: 120px;
  }
  .centered {
    text-align: center;
  }
  .payer-icon {
    margin: -2px 5px 0 0;
    vertical-align: middle;
  }
}

.plDuePopup-table tbody tr td {
  border: 0 !important;
}

.popover {
  padding: 0;
  max-width: 100%;

  .popover-table {
    margin-bottom: 0;
    padding: 0;
    border: 0;
    font-size: 12px;

    > thead > tr > th {
      background-color: $blue;
      color: white;
      padding: 5px 15px;
    }

    > tbody > tr {
      > td {
        padding: 10px 15px !important;
        background-color: white;
      }

      &.oddRow > td {
        background-color: #e8e8e8;
      }

      &.due > td {
        background-color: mix($red, white, 10%);
        color: $red;
        border-bottom: 0;
      }

      &.today > td {
        text-align: center;
        color: $blue;

        span {
          position: relative;

          &:before,
          &:after {
            content: "";
            position: absolute;
            height: 8px;
            border-bottom: 1px dashed $blue;
            top: 0;
            width: 150%;
          }

          &:before {
            right: 100%;
            margin-right: 15px;
          }

          &:after {
            left: 100%;
            margin-left: 15px;
          }
        }
      }
    }
  }

  .loader {
    min-height: unset;
  }
}

@mixin navlink-state($selector) {
  &#{$selector} > a {
    &,
    &:hover,
    &:focus {
      @content
    }
  }
}

// Navbar Default styling
.navbar.navbar-default {
  align-items: stretch;
  flex-flow: row wrap;
  width: auto !important;
  padding: 0 !important;
  background-color: $navbar-default-bg;
  border-color: $navbar-default-border;
  border-bottom: 0;

  a:hover {
    text-decoration: none;
  }

  .navbar-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    float: left;

    @include media-breakpoint-down(md) {
      float: none;
    }
  }

  .navbar-brand {
    height: $navbar-height;
    padding: 12px;
    margin: 0;
    color: $navbar-light-brand-color;

    &:hover,
    &:focus {
      color: $navbar-light-brand-color;
      background-color: $navbar-light-brand-hover-color;
    }

    img {
      height: 100%;
      vertical-align: bottom;
    }
  }

  .navbar-text {
    color: $navbar-default-color;
  }

  .navbar-toggler {
    float: none;
    border-color: $navbar-light-toggler-border-color;
    @include media-breakpoint-down(sm) {
      display: block;
    }

    &:hover,
    &:focus {
      background-color: $navbar-toggler-hover-color;
    }

    .icon-bar {
      background-color: $navbar-light-toggler-icon-bg;
    }
  }

  .navbar-collapse {
    justify-content: flex-end;
    border-color: $navbar-default-border;
    margin-right: -15px;
    max-height: 90vh;
  }

  .navbar-form {
    position: relative;
    border: 0;
    padding: 0;
    margin: 7px 20px 7px 0;

    @include media-breakpoint-up(lg) {
      order: 10;
    }
    @include media-breakpoint-only(md) {
      position: fixed;
      right: 0;
      top: 0;
      margin-right: 8px;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 8px;
    }

    .form-group {
      margin: 0;
      border-radius: 50px;

      &.has-error {
        @include box-shadow($red);

        input, button {
          border-color: $red;
        }
      }
    }
    .form-control {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    .input-group-btn > .btn {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    .input-group-focus {
      border-radius: 50px;
    }
  }

  // Dropdown menu items
  .navbar-nav {
    flex-flow: row nowrap;
    margin-bottom: -1px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin: 0;
    }

    > .nav-item {
      > a {
        display: block;
        padding: 15px;
        margin: 0;
        color: $navbar-default-link-color;

        &:hover,
        &:focus {
          color: $navbar-default-link-hover-color;
          background-color: $navbar-default-link-hover-bg;
        }

        @include media-breakpoint-down(sm) {
          padding-right: 8px;
          padding-left: 8px;
        }

        .fa-angle-down {
          display: inline-block;
          margin-left: 5px;
        }
      }

      @include navlink-state(".active") {
        color: $navbar-default-link-color;
        background-color: transparent;
        font-weight: bold;
        border-bottom: 3px solid $navbar-default-link-color;
        height: $navbar-height !important;
      }
      @include navlink-state(".show") {
        color: $navbar-default-link-active-color;
        background-color: $navbar-default-link-active-bg;
      }
      @include navlink-state(".disabled") {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
      }
    }
  }

  .dropdown-menu {
    @include media-breakpoint-only(md) {
      position: absolute;
    }
  }

  .dropdown-item {
    &.nav-item {
      padding: 0;

      a, button {
        display: block;
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        background-color: transparent;
      }
    }

    &.active, &:hover, &:focus, &:focus-within {
      background-color: $navbar-default-link-active-bg;
      a, button {
        color: $navbar-default-link-active-color;
        outline: none;
      }
    }
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: $navbar-default-link-color;

    &:hover {
      color: $navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: $navbar-default-link-color;

    &:hover,
    &:focus {
      color: $navbar-default-link-hover-color;
    }

    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
      }
    }
  }

  // shadow box, which is kept separate so it won't display on top of other sticky headers

  + .navbar-shadow-box {
    position: fixed;
    z-index: 1005;
    top: 0; // overwritten by js
    width: 100%;
    height: $navbar-height;
    margin-top: -$navbar-height;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.navbar-collapse {
  // this is outside the .navbar-default block so that it can be overruled by the built-in collapse when necessary
  // though really just about everything should be extracted from that big block. It's too deeply nested.
  display: flex;

  @include media-breakpoint-down(md) {
    // without this, the menu doesn't animate correctly
    display: block;
  }
}

// these are an overlay, not technically inside the nav
.nav-search-overlay {
  margin-top: 3px;
  padding: 8px 10px;
  font-size: 1rem;

  .arrow {
    display: none;
  }

  .form-group {
    display: flex;
    align-items: baseline;
    flex-flow: row nowrap;
    margin: 0;
  }
  fieldset, .form-group {
    + .form-group {
      padding-top: 5px;
    }
  }

  .nav-search-scope {
    min-width: 250px;
    margin-top: -3px;

    .form-label {
      display: block;
      width: auto;
      font-size: 1em;
      margin-bottom: 0;
      padding: 5px 10px 5px 5px;
    }

    .form-check {
      margin: 0;
      flex-grow: 1;
      text-align: center;

      input {
        display: none;

        &:checked + label {
          background-color: $blue;
          color: $white;
        }
      }

      label {
        display: block;
        width: 100%;
        padding: 5px 10px;
        border-radius: 0;
        border-left: none;
      }

      &:hover {
        background-color: $gray-lightest;
      }
    }

    .form-check:first-of-type {
      label {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid rgba(0, 0, 0, 0.125);
      }
    }

    .form-check:last-of-type {
      label {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .nav-search-flag-name .disabled {
    cursor: default;
  }
}

// Search Bar

.search-bar, .search-bar input {
  height: 36px; // pretty good default
}

.search-bar.input-group {
  input.form-control {
    padding-right: 0;
    border-color: $gray-lighter;
    border-right: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

    &:focus {
      border-color: $blue;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !important;
    }
  }

  .btn .fa {
    vertical-align: middle;
  }

  .btn-spacer {
    border: 1px solid $gray-lighter;
    border-left: none;
    cursor: default;
  }

  .btn:disabled {
    opacity: 1;

    .fa {
      opacity: 0.65;
    }
  }
}

// clear button on search inputs
input[type=search]::-webkit-search-cancel-button {
  cursor: pointer;
}

// Input Group Focus State fix

.input-group-btn {
  z-index: 3;

  > .btn {
    margin-left: 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;

    &:hover {
      margin-left: 0;
      border-left: 1px solid #adadad;
    }
  }
}

.input-group-focus {
  border-radius: 4px;
  -webkit-transition: box-shadow ease-in-out 0.15s;
  transition: box-shadow ease-in-out 0.15s;

  &.input-group {
    @include box-shadow(rgba(102, 175, 233, 0.6));

    @include is-or-contained-by(".has-error") {
      @include box-shadow($red, 6px);
    }
    @include is-or-contained-by(".has-warning") {
      @include box-shadow(#67b168, 6px);
    }
    @include is-or-contained-by(".has-success") {
      @include box-shadow(#c0a16b, 6px);
    }
  }

  .input-group-btn > .btn {
    border-color: $blue;
  }
}

.input-group-btn > .btn,
.input-group.input-group-focus .input-group-text {
  .has-error & {
    border-color: $red !important;
  }
  .has-success & {
    border-color: #2b542c !important;
  }
  .has-warning & {
    border-color: #66512c !important;
  }
}

.has-error .help-block {
  color: $red;
}
.form-group .help-block {
  font-size: 12px;
}

// Toggle Switch

.toggle-switch {
  vertical-align: bottom;
  height: 25px;
  display: inline-flex;

  label {
    position: relative;
    display: block;
    float: left;
    height: 22px;
    width: 44px;
    background: #ccc;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 0;

    &:after {
      position: absolute;
      left: 2px;
      top: 2px;
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 100px;
      background: #fff;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
      content: "";
      transition: all 0.3s ease;
    }

    &:active:after {
      transform: scale(1.15, 0.85);
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked ~ label {
      &:after {
        left: 25px;
      }
    }

    &:disabled ~ label {
      background: #d5d5d5;
      pointer-events: none;

      &:after {
        background: #bcbdbc;
      }
    }
  }
}

// Cards

.card-title {
  font-size: 16px;
}

.card {
  position: relative;
  margin-bottom: $card-margin-after;
  overflow: hidden; // needs to be like this to prevent weird overflow from tall tables, but it's possible that items that need to break out (e.g. Selects) might get caught by this. The solution is portalling; we try to automatically determine the correct portal target to use in hooks.usePortal, but if that doesn't work you'll need to specify the portal target manually.

  > .card {
    margin: $card-spacer-x;
    // XXX this might not be used ever?
  }

  > .card-header {
    padding: 10px 15px;
    background-color: white;
    border-bottom: 0;

    .card-collapse-clickable {
      color: $blue;
      display: inline-block;
      margin-right: 10px;
      &[aria-expanded="false"] > .fa:before {
        content: "\f0d7";
      }
      &[aria-expanded="true"] > .fa:before {
        content: "\f0d8";
      }
    }
    .card-title {
      display: inline-block;
      margin: 0;

      em {
        font-style: normal;
        font-weight: 300;
      }
    }
    .filter-link {
      display: inline-block;
      color: $blue;
      cursor: pointer;
      text-decoration: none;
      i {
        display: inline-block;
        margin-left: 5px;
      }
      &:hover {
        text-decoration: none;
        color: mix($blue, black, 10%);
      }
      &[aria-expanded="false"] .fa:before {
        content: "\f078";
      }
      &[aria-expanded="true"] .fa:before {
        content: "\f077";
      }
    }

    .icon-button {

    }
  }
  .card-footer {
    background-color: white;

    .filter-status {
      display: inline-block;
      color: $blue;
      li {
        margin-right: 10px;
        span {
          font-weight: bold;
        }
      }
    }
    .entry-count {
      min-width: 200px;
      font-size: 12px;
      color: inherit;
    }
    h4 {
      font-size: 12px;
      color: inherit;
      font-weight: bold;
      display: inline-block;
      margin: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      li {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        color: inherit;
      }
    }
  }
  &.card-table {
    .card-body {
      padding: 0;
      .filters {
        background-color: mix($blue, white, 15%);
        padding: 15px;
      }
    }
  }

  .table-wrapper {
    border-top: 1px solid #ddd;
  }
}

.card-header-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.card-body {
  min-height: 0;
}

// fieldset cards

fieldset.card {
  padding: 0 1rem;


  legend {
    width: unset;
    padding: 0 0.5rem;
    border: inherit;
    border-radius: 0.25rem;
    background-color: white;
  }
}

// tabs

.tab-content {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  max-height: 100%;

  > .active {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    min-height: 0; // prevent issue on Firefox where it effectively defaults to min-height:min-content
  }
  .card {
    max-height: 100%;
    min-height: 0;
  }
}

// charts

.chart-container {
  height: 100%;
  min-height: 300px;
  display: block;
}

// Highchart credit hide

text.highcharts-credits {
  display: none;
}


// Standard table styles

table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-collapse: collapse !important;

  td {
    flex-grow: 1;
    padding: 8px;
    border: 0;

    a {
      text-decoration: underline;
      outline: none;

      &:hover {
        font-weight: bold;
      }
    }

    .fa {
      display: inline-block;
      margin-right: 5px;
      margin-top: 2px;
    }

    .fa-h-square {
      float: left;
      color: $blue;
    }

    .fa-plus-square {
      float: left;
      color: $red;
    }

    .fa-times-circle-o {
      float: left;
      color: $red;
    }

    .fa-check-circle-o {
      float: left;
      color: $green;
    }

    .dual-icon-compliance {
      margin: 0;
    }
  }

  tr {
    display: flex;
    flex-flow: row nowrap;

    &:nth-child(odd) td {
      background-color: $lightgrey; //mix($orange, white, 15%);
    }
    &:nth-child(even) td {
      background-color: white;
    }

    &.due,
    &.attention {
      td {
        background-color: mix($red, white, 15%);
        border-bottom: 1px solid mix($red, white, 35%);
      }
    }

    &.complete {
      td {
        background-color: mix($green, white, 15%);
      }
    }

    &.selected {
      td {
        background-color: mix($orange, white, 60%);
        color: #333;

        a {
          color: #333;
        }
      }

      &:hover td {
        background-color: mix($orange, white, 85%);
      }
    }
  }
}

.table-hover tbody tr:hover > td {
  background-color: lighten($secondary-color, 5%);
}

// Datatables Search Hightlighting

table.dataTable span.highlight {
  background-color: #ffff88;
  border-radius: 0.28571429rem;
}

table.dataTable span.column_highlight {
  background-color: #ffcc99;
  border-radius: 0.28571429rem;
}

// Datatables Fontawesome Integration for Sort Icons

.dataTables_scrollHead table.dataTable thead th {
  position: relative;
  background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  bottom: 10px;
  right: 8px;
  display: block;
  font-family: FontAwesome !important;
}

table.dataTable thead th.sorting:after {
  content: "\f0dc" !important;
  font-size: 0.8em;
  padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
  content: "\f0de" !important;
}

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd" !important;
}

table.DTFC_Cloned tr {
  td:last-child {
    border-right: 1px solid #ddd;
  }
}

// react-table styles

.ReactTable .rt-tr-group {
  flex-grow: 0; // prevent rows from stretching their height when they don't fill the vertical space
}

.ReactTable .-pagination .-btn:not([disabled]) {
  background-color: $blue;
  color: white;

  &:focus {
    background-color: darken($blue, 15);
  }
  &:hover, &:active {
    background-color: darken($blue, 20);
  }
}

// lists with table styles
ul.dataTable {
  a:hover {
    font-weight: bold;
  }
}

ul.table-striped {
  li:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
ul.table-hover {
  li:hover {
    background-color: lighten($secondary-color, 5%);
  }
}

// Forms

.form-group {
  position: relative;

  .well {
    padding: 0;
    background-color: white;
    border-color: rgb(204, 204, 204);
  }
  label {
    font-weight: normal;
  }
}

.bootstrap-select {
  > .btn {
    padding: 6px 12px 7px 12px;
  }
}

.input-group {
  .btn {
    padding: 6px 12px 7px 12px;
  }
}

.input-group-btn {
  > .btn {
    border-left-width: 0;
  }
}

.input-group-focus {
  .form-control {
    border-right: 0;
  }
  .input-group-text {
    border-color: #66afe9;
  }
}

.bootstrap-select.btn-group .dropdown-menu li a {
  outline: none;
}

.form-group .form-control {
  height: 36px; // to ensure all inputs are the same height
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  border-color: $gray-lighter;

  &:focus {
    border-color: $blue;
    @include box-shadow($blue);
  }

  &[type=number] {
    padding-right: 0.375rem; // matches top and bottom padding

    + .Select-clear-zone {
      margin-right: calc(0.375rem + 15px); // 15px is the width of the step buttons
      padding-right: 0.375rem !important;
    }
  }
}
.btn-spacer {
  cursor: inherit;
  padding: 6px 12px 7px 12px;

  &:hover {
    background-color: inherit;
    color: inherit;
  }

  .input-group-btn &:hover {
    border-color: $gray-lighter;
    border-left-style: hidden;
    margin-left:1px;
  }
  .input-group-focus .input-group-btn & {
    border-color: $blue;
  }
}

.form-group {
  .select__indicator {
    padding: 7px; // to make the dropdown indicator small enough  that it doesn't stretch the dropdown to 38px high
  }

  .select__control {
    min-height: 36px;
  }

  .Select.is-disabled > .Select-control {
    // match Bootstrap styles
    background-color: #eee;
    color: #555;
    cursor: not-allowed;
  }
}

.form-control {
  &:focus {
    outline: inherit;
    border-width: 1px;
  }

  &::placeholder, ::placeholder {
    color: $gray-light;
  }
}

.input-decoys {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;

  input {
    position: absolute !important;
    height: 0 !important;
    width: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }
}


.active_filter_field_label {
  font-weight: bold !important;
}

.active_filter_field_input {
  .form-control {
    @include box-shadow($blue);
  }

  .select__control {
    @include box-shadow($blue);
  }
}

.form-field-error, .filter_field_error {
  .form-control {
    border: 1px solid transparent !important;
  }
  .form-control, .select__control {
    @include box-shadow($red);

    &:focus {
      border-color: $red;
    }
  }
}

.filter_field_error_dropdown,
.filter_field_error .Select {
  .form-control {
    box-shadow: none !important;
  }
  .Select-control {
    border: transparent !important;
    @include box-shadow($red);
  }
}

.active_filter_field_button {
  button {
    @include box-shadow($blue);
    &:focus {
      border-color: $blue;
    }
  }
}

.error_label {
  color: $red !important;
  font-weight: bold !important;
}
.warning_label {
  color: darken($orange, 10%) !important;
  font-weight: bold !important;
}

.required-indicator {
  color: $red;
}

.is-focused:not(.is-open) > .Select-control {
  border: transparent !important;
}

// dropdowns

.select, .select-creatable {
  input {
    box-shadow: none !important;
  }

  .select__placeholder {
    color: $gray-light;
  }

  .select__multi-value__label {
    white-space: normal;
  }
}

.select__control {
  box-shadow: none;

  &:focus-within {
    border-color: $blue;
    @include box-shadow($blue);
  }

  &--menu-is-open .select__indicator {
    transform: rotate(180deg);
  }
}
.select .select__menu {
  z-index: 10;
  border-color: $gray-lighter;
}

div.select__menu-portal { // the "div" is just to increase its specificity
  z-index: 9999; // otherwise it can appear behind a modal
}

.select__option {
  word-break: break-word;
}


// dollar input
.dollar-field {
  input {
    padding-left: 1.5rem;
  }
  .dollar-sign {
    position: absolute;
    bottom: 7px;
    left: 10px;
    color: $gray-light;
  }
  &.active_filter_field_input .dollar-sign {
    color: $gray-dark;
  }
}

// file input

.custom-file-label {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;

  &:after {
    // emulates btn-secondary
    @extend %btn-base;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    margin: -0.375rem -0.75rem -0.375rem auto;
    color: $gray-dark;
    background-color: $gray-lighter;
  }
}
.custom-file-input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;

  &::-webkit-file-upload-button {
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:active {
    + .custom-file-label:after {
      background-color: $gray-dark;
      border-color: transparent;
      color: white;
    }
  }
}

// a certain variant of form with a lot of filters

.filters {
  background-color: mix($blue, white, 15%);
  padding: 15px;
}

// display of selected filters

.filter-status {
  flex: 1;
  display: inline-block;
  color: $blue;

  &.filters-none-active {
    display: none !important;
  }

  dl {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
    font-size: 14px;

    > div {
      display: inline;
      margin-right: 10px;
      color: inherit;
    }
  }

  dt {
    margin-right: 5px;
    vertical-align: top;
  }

  dt, dd {
    display: inline;
    margin-bottom: 0;
  }

  .category-filters {
    display: inline-flex;
    flex-flow: row nowrap;

    dd {
      flex-grow: 1;
    }
  }

  .category-filters-block {
    display: flex;

    dd {
      padding-left: 12px;
      text-indent: -12px;
    }
  }
}

// filter favorites

.filter-favorites-dropdown {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;

  > button {
    border: 0;
    line-height: 0;
    margin: -13px 0 -10px;
    padding: 10px 0;
  }

  .clickable-icon {
    font-size: 18px;
  }

  .dropdown-item {
    &.active {
      background-color: $navbar-default-link-active-bg;
    }

    &:hover, &:focus {
      background-color: lighten($blue, 35%);
      outline: none;

      &.active {
        color: inherit;
      }
    }
    &:active {
      background-color: lighten($blue, 30%);
      color: inherit;
    }
  }
}

.filter-favorites-popover {
  padding: 5px 0 5px 10px;
  max-width: 400px;

  .filter-status dl {
    display: flex;
    flex-flow: column nowrap;
  }
}

.filter-favorites {
  .card {
    overflow: hidden;
    margin-bottom: 0;

    > .card-body > .btn {
      margin: 10px;
    }
  }

  .card-table {
    margin-bottom: 1rem;
  }

  .grid-cell-header {
    border-top: none !important;
  }

  .selectable {
    cursor: pointer;
  }
  .selected {
    background-color: lighten($blue, 25%);

    &.highlight {
      background-color: lighten($blue, 20%);
    }
  }

  .filter-status {
    display: inline;
    color: inherit;
  }

  .grid-cell .category-filters {
    display: inline;
  }
}

.filter-favorite-actions {
  text-decoration: none !important;

  > button {
    min-width: 26px;
  }

  .fa-undo {
    text-decoration: none;
    color: $body-color;
  }
}

/* CSS REQUIRED */
.state-icon {
  right: 5px;
  margin-top: 2px;
  float: right;
}

.list-group-item-primary {
  color: $blue;
  background-color: mix($blue, white, 10%);
}

.list-group-item {
  padding: 5px 15px;
  &.active,
  &:hover {
    color: $blue;
    background-color: mix($blue, white, 10%);
    border-color: #ddd;
  }
  &:hover {
    color: white;
    background-color: #337ab7;
    border-color: #337ab7;
  }
}

/* DEMO ONLY - REMOVES UNWANTED MARGIN */
.well .list-group {
  margin-bottom: 0;
}

/* Fancytree overrides */
ul.fancytree-container {
  font-family: "Heebo", arial, sans-serif;
  font-size: 10pt;
  border: 0;
  outline: none;
  li {
    margin: 5px 0 0 0;
  }
}

.fancytree-plain span.fancytree-title {
  border: 1px solid transparent;
}

.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-focused span.fancytree-title {
  border-color: #3399ff;
}

.fancytree-plain span.fancytree-active span.fancytree-title,
.fancytree-plain span.fancytree-selected span.fancytree-title {
  background-color: #f7f7f7;
  border-color: #dedede;
}

.fancytree-plain span.fancytree-node span.fancytree-selected span.fancytree-title {
  font-style: italic;
}

.fancytree-plain span.fancytree-node:hover span.fancytree-title {
  color: $blue;
  background-color: mix($blue, white, 10%);
  border-color: #ddd;
}

.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-active span.fancytree-title,
.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-selected span.fancytree-title {
  color: $blue;
  background-color: mix($blue, white, 10%);
  border-color: #ddd;
}

// Modals

.modal-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  background-color: $blue;
  color: white;
  padding-bottom: $modal-header-padding-y - 4; // the line-height makes equal padding look uneven
  border-top-left-radius: $modal-content-border-radius - 2;
  border-top-right-radius: $modal-content-border-radius - 2;
  // without the -2 we get white lines in the corners

  &:before, &:after {
    content: none;
  }
}

.modal-body {
  #measures-list {
    .overflow {
      max-height: 480px;
      overflow-y: auto;
    }
  }
  #providers-list {
    background-color: white !important;
    padding: 0;
    max-height: 250px;
    overflow-y: auto;
  }
}

.modal-footer {
  flex-flow: row nowrap;
  align-items: flex-start;

  .btn {
    white-space: nowrap;
  }

  // TODO the alignment stuff below is kinda wrong. Generally we want most footer buttons to pull right by default, right?

  // puts .pull-lefts first. Will not actually push them to the left of the modal unless other elements are .pull-right.
  > .pull-left {
    order: -1;
  }

  // first .pull-right will get margin-left: auto; others get normal margins.
  > .pull-right {
    margin-left: auto;
    ~ .pull-right {
      margin-left: $modal-footer-margin-between / 2;
    }
  }
}

// fade-in fix

.fade.show {
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-backdrop.modal-backdrop-front {
  z-index: 1060;
  +.modal {
    z-index: 1070;
  }
}

.modal.fade.show .modal-dialog {
  transform: translate(0);
}

// dropdown fix

.dropdown-toggle {
  cursor: pointer;

  &:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    color: transparent;
  }
  &:hover:after, &[aria-expanded=true]:after {
    color: inherit;
  }
}

// tabs fix

.nav-tabs {
  position: relative;
  z-index: 10;
  margin-bottom: -1px;
  flex-flow: row nowrap;
  flex-shrink: 0;
  overflow-x: auto;

  > .nav-link {
    margin-right: 2px;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    background-color: lighten($lightgrey, 5%);
    color: $gray-dark;
    font-size: 15px;
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }

    &.disabled {
      pointer-events: none;
      color: lighten($gray-medium, 10%);
    }
    &.active {
      color: $gray-darkest;
    }
    &:not(.active):hover {
      background-color: $gray-lightest;
      border-color: $gray-lighter $gray-lighter #ddd;
    }
  }
}

.modal .nav-tabs {
  margin-bottom: 0;
  border-bottom: none;

  > .nav-link {
    padding: 10px 15px 6px;
    border: 0 solid transparent;
    background-color: transparent;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857;
    text-decoration: none;

    &:not(.active):hover {
      background-color: #eee;
    }

    &.active {
      &, &:hover, &:focus {
        background-color: mix($blue, white, 15%) !important;
        border-bottom-color: transparent;
        color: #333;
        cursor: default;
      }
    }
  }
}


// "check all" button

.check-all {
  border-radius: 5px;
  background-color: #ddd;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #333;
    color: #ddd;
  }

  input {
    display: none;
  }

  .fa {
    width: 1em;
    margin-right: 3px;
  }
}

// icons

.payer-icon {
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  &.term-45 {
    filter: grayscale(1);
  }

  + :not(.dual-icon-compliance) {
    margin-left: 9px;
  }

  + .payer-name {
    display: inline-block;
    margin-top: 2px;
  }
  ~ .product_class-name {
    display: inline-block;
    vertical-align: text-top;
    margin: 2px 6px 0 3px;
  }
}

.guide-link {
  display: inline-block;
  padding: 3px 8px;
  line-height: 0; // to remove extra vertical space
  font-size: 1rem;
  text-decoration: none !important;

  > span {
    text-decoration: underline;
  }

  &:hover {
    .indicator-info {
      color: darken($blue, 30) !important;
    }
  }

  &.white {
    .indicator-info {
      color: $white;
    }
    &:hover {
      .indicator-info {
        color: $lightgrey !important;
      }
    }
  }
}

h1 .guide-link {
  vertical-align: middle;
  font-size: 1.1rem;
}

.guide-link-measure {
  overflow: visible;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 5px;
  text-decoration: none !important;

  &:hover {
    color: darken($blue, 30) !important;
  }
}

// conditional display

.sm-down {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.md-down {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
.lg-down {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.sm-up {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.md-up {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.lg-up {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

// Athena and external POC patient matching

.poc-error {
  padding: 50px;
  text-align: center;
  font-size: 1.25rem;
}


// dual icons

.dual-icon-group {
  overflow: visible !important;
}

.dual-icon {
  display: inline-block;
  overflow: visible !important;
  > img {
    margin: 0;
  }
}

.dual-icon-compliance {
  display: inline-block;
  font-size: 0.9em;
  vertical-align: sub;
  transform: translate(-0.4em, 0.15em);
  line-height: 0.9em;
  border-radius: 100px;
  padding: 1px; // nice little white circle around the icon
  background-color: white;

  &:not(.fa) {
    // don't display if there's no icon specified
    display: none;
  }
}

.dual-icon-group-tooltip {
  margin: 7px;
  padding: 7px 9px 5px 7px;
  max-width: none;
  font-size: 1rem;

  dl {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2px 5px;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    line-height: normal;

    :first-child {
      text-align: right;
    }
  }
  dd {
    margin-bottom: 0;
  }
  .payer-icon {
    margin-right: 5px;
    vertical-align: text-top;
  }
}

// color picker component

.color-picker {
  .form-control {
    padding: 0.375rem 2.75rem; // extra space for color indicator and clear button
  }

  .color-prefix {
    position: absolute;
    display: flex;
    align-items: center;
    height: 36px; // input height
    padding-left: 0.75rem; // same as input
    color: $gray-light;
  }
  .color-swatch-sm {
    margin-bottom: 0;
    margin-right: calc(0.75rem - 4px);
  }
}

.color-picker-tooltip {
  .tooltip-inner {
    padding: 1px; // provide a little border around the tooltip
    border-radius: 8px; // the .block-picker inside it has a border-radius of 6px, and it's awkward to change
  }
}

// color swatches

.color-swatch-sm {
  display: inline-block;
  box-sizing: content-box;
  width: 0.8em;
  height: 0.8em;
  margin-bottom: -1px; // to align better with text
  border: 1px solid $gray-lighter;
  border-radius: 3px;
}

// page header component

.page-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;

  .filter-button {
    background-color: transparent;

    &:hover {
      background-color: darken($blue, 10);
    }
  }
}


// row where first column grows or shrinks to match the second's height

.row-equal-columns {
  align-items: stretch;

  > :first-child {
    display: flex;
    flex-flow: row nowrap; // this lets the height update correctly. bit of a hack though
    align-items: flex-start;

    > .card {
      position: absolute; // may need to override this on resize if the layout changes so the columns are in different rows, and add a more specific max-height.
      flex-grow: 1;
      display: flex;
      flex-flow: column nowrap;
      overflow: hidden;
      width: calc(100% - #{$card-spacer-x * 2}); // 100% would include the parent's padding
      max-height: 100%;

      > .card-body {
        display: flex;
        flex-direction: column;
      }
    }
  }
  > :nth-child(2) {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }

  > * > .card, .tab-pane > .card {
    margin-bottom: 0;
  }
}
